/**
 * Create an object for communicating across tabs
 * @param {string} name
 * @return {CrossTabEventBus<EVENT_KEY>}
 */
function createCrossTabEventBus(name) {
    //BroadcastChannel should not be assumed to be supported
    if (!window.BroadcastChannel) {
        return new NoEventBus();
    }
    return new BroadcastChannelEventBus(name);
}
class NoEventBus {
    emit(key, value) { }
    subscribe(key, callback) {
        return () => { };
    }
    dispose() { }
}
class BroadcastChannelEventBus {
    constructor(name) {
        this._subscribers = [];
        this._broadcastChannel = new window.BroadcastChannel(name);
        this._broadcastChannel.onmessage = evt => this.processMessage(evt);
    }
    emit(key, value) {
        this._broadcastChannel.postMessage({ key, value });
    }
    subscribe(key, callback) {
        const subscriber = { key, callback };
        this._subscribers.push(subscriber);
        return () => {
            const idx = this._subscribers.indexOf(subscriber);
            if (idx >= 0)
                this._subscribers.splice(idx, 1);
        };
    }
    unsubscribe() { }
    dispose() {
        //Stop listening to incoming messages, dispose of our broadcastchannel
        this._broadcastChannel.onmessage = () => { };
        this._broadcastChannel.close();
    }
    processMessage(evt) {
        //Assume all received events are fired by a BroadcastChannelEventBus instance in another window
        this._subscribers.forEach(subscriber => {
            if (subscriber.key === evt.data.key) {
                subscriber.callback(evt.data.value);
            }
        });
    }
}
export { createCrossTabEventBus };
