<template>
  <div id="page">
    <div id="topbar"></div>
    <div id="main">
      <svg id="dots" width="132px" height="58px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="dots" fill="var(--text-primary-500, #868686)">
            <circle id="dot1" cx="25" cy="30" r="13"></circle>
            <circle id="dot2" cx="65" cy="30" r="13"></circle>
            <circle id="dot3" cx="105" cy="30" r="13"></circle>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// A page skeleton. This is displayed while the subapp is mounting/loading to take over the page. It visually mimics a page.
export default {
  name: 'Skeleton',
};
</script>

<style scoped>
#page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--text-primary, #313741);
}

#topbar {
  width: 100%;
  height: 120px;
  background-color: var(--main-background, #fff);
  border-bottom: 1px solid var(--main-border, #c9d3df);
  border-top-left-radius: 1.35rem;
}

#main {
  background-color: var(--main-applicationBackground, #dde9f2);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#dots {
  opacity: 0;
  animation: fadeIn 250ms ease-in 250ms forwards;
}

#dots #dot1 {
  animation: load 1s infinite;
}

#dots #dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
