import password from './password';
import router from '@app/router';
import authentication from '@app/../app/api-generated/authentication';
import account from '@app/../app/api-generated/account';
import {T, messages} from '@app/utils/i18n';
import forgotPassword from './forgotPassword';
import {cache, addToast} from '@dnx/core';

import {DnxJwtToken} from '../../models/jwt';
import {useAuthentication} from '../../../stores/authentication';
import {useQueryClient} from '@tanstack/vue-query';

const errorNotification = (ctx, message) => {
  //Dnx response/string
  if (typeof message === 'object')
    message = message.value?.resourceKey ?? message.resourceKey ?? message.value?.message ?? message.message;

  addToast('error', T(message));
};

const sessionExpired = ctx => {
  //No session, nothing could've actually expired
  if (!ctx.state.token) return false;

  //Token not yet expired
  const token = DnxJwtToken.parse(ctx.state.token);
  if (!token.isExpired()) return false;

  //Wipe token, send out an error notification regarding our expired token
  ctx.commit('setToken', undefined);
  errorNotification(ctx, messages['SESSION_EXPIRED']);

  return true;
};

const createLoginActionHandler = callApiWithPayload => {
  return async (context, payload) => {
    //Session already expired
    if (sessionExpired(context)) return;

    try {
      const response = await callApiWithPayload(payload);
      if (!response.success) {
        errorNotification(context, response);
        return;
      }

      context.commit('setToken', response.value.token);

      return response;
    } catch (e) {
      console.error(e);
    }
  };
};

const LoginModule = {
  namespaced: true,
  modules: {
    password: password,
    forgotPassword: forgotPassword,
  },
  state: {
    ssoSettings: {},
    token: undefined,
    tfaQrCode: undefined,
    isConnected: false,
  },
  getters: {
    loggedIn(state) {
      const token = DnxJwtToken.parse(state.token);

      return !token.isExpired() && !token.tfaPending && !token.passwordChangePending;
    },
    isExpiredToken(state) {
      const token = DnxJwtToken.parse(state.token);
      return token.isExpired();
    },
    isTfaConfirm(state) {
      return !DnxJwtToken.parse(state.token).tfaConfirmed;
    },
    isTfaToken(state) {
      return DnxJwtToken.parse(state.token).tfaPending;
    },
    isChangePasswordToken(state) {
      return DnxJwtToken.parse(state.token).passwordChangePending;
    },
    userId(state) {
      return DnxJwtToken.parse(state.token).userId;
    },
    token(state) {
      return state.token;
    },
    tfaQrCode(state) {
      return state.tfaQrCode;
    },
    isConnected(state) {
      return state.isConnected;
    },
    isSingleSignOnAvailable(state) {
      return state.ssoSettings && state.ssoSettings.isAvailable;
    },
    ssoTranslationKey: state => {
      return state.ssoSettings ? state.ssoSettings.translationKey : 'AUTHENTICATION_BUTTON_LOGIN_SSO';
    },
  },
  mutations: {
    setToken(state, value) {
      state.token = value;
      //FIXME: Now set by login/app-full. Add it to runtime so subapps have access.
      // The auth/login should be the only one with set access!
      Window.runtime.token = value;

      this.commit('login/initializeAuthentication');
    },

    setTfaQrCode(state, value) {
      state.tfaQrCode = value;
    },

    setIsConnected(state, value) {
      state.isConnected = value;
    },
    setNewTfaCode(state, value) {
      state.tfaQrCode = value;
    },
    setSingleSignOn(state, value) {
      state.ssoSettings = value;
    },
    updateNextValidation(state) {
      state.nextValidation = +new Date() + 5 * 60 * 1e6; /*5min*/
    },

    initializeAuthentication() {
      if (!this.getters['login/loggedIn']) return;

      // pinia might not be ready yet
      try {
        useAuthentication().login(Window.runtime.token);
      } catch {}
    },
  },
  actions: {
    async resetTfa(context, userId) {
      const response = await account.resetTfa(userId);

      if (response.success) {
        context.commit('setTfaQrCode', undefined);
      } else {
        throw response.message;
      }

      return response.success;
    },
    async logout(context) {
      const authStore = useAuthentication();
      if (!(await authStore.logout())) return;

      sessionStorage.clear();

      context.commit('setToken', undefined);
      Window.runtime.store.commit('passwordPolicies/reset'); // <-- should be replaced with store registering itself

      cache.destroy();
      // check if we need to redirect to an external system
      const signOutResponse = await authentication.signout();

      if (
        signOutResponse.success &&
        signOutResponse.value &&
        signOutResponse.value.shouldRedirect &&
        signOutResponse.value.redirectUrl
      ) {
        document.location.href = signOutResponse.value.redirectUrl;

        return;
      }
      await router.push('/login');
    },
    loginWithSSO(context) {
      document.location.href = context.state.ssoSettings.loginUrl;
    },
    async loadSsoSettings(context) {
      const settings = (await authentication.getSsoSettings()).value;
      context.commit('setSingleSignOn', settings);
    },
    async loadTfaQrCode(context) {
      const qrCodeDetails = (await authentication.tfaQrCode()).value;

      context.commit('setTfaQrCode', qrCodeDetails);
    },
    async loadNewTfaCode({commit}) {
      const newQrCode = (await authentication.getNewTfaSettings()).value;
      commit('setNewTfaCode', newQrCode);
    },
    // eslint-disable-next-line no-empty-pattern
    async updateNewTfaCode({}, data) {
      await authentication.putNewTfaSettings(data);
    },
    loginWithCredentials: createLoginActionHandler(payload => authentication.loginWithCredentials(payload)),
    loginWithTfaCode: createLoginActionHandler(payload => authentication.loginWithTfaCode(payload)),
    confirmTfaCode: createLoginActionHandler(payload => authentication.confirmTfaCode(payload)),
    updatePassword: createLoginActionHandler(payload => authentication.updatePassword(payload)),

    async verifyLoginState(context) {
      //sessionExpired has side effects and should thus be executed prior to `loggedIn`
      if (sessionExpired(context)) return false;
      try {
        if (context.state.token && context.state.nextValidation >= new Date()) return true;

        const {statusCode, value} = await authentication.validate();
        const valid = statusCode !== 401 && statusCode !== 403;
        if (!valid) {
          context.commit('setToken', undefined);
        } else if (value && value.token) {
          // received a new token
          context.commit('setToken', value.token);
        }
        if (valid) {
          context.commit('updateNextValidation');

          // context.state.nextValidation = +(new Date()) + 5*60*1e6 /*5min*/
        }
        return valid;
      } catch (e) {
        // assume we're still valid when our request gets cut off, happens on reload etc.
        if (e.name === 'NetworkError' || e.name === 'TypeError' || e.name === 'AbortError') return true;

        // The AzureAD implementation makes the Validate() endpoint redirect which in turn causes an exception
        // we still need to call setToken to show the login interface
        context.commit('setToken', undefined);
        return false;
      }
    },
  },
};

export default LoginModule;
