import {createStore} from 'vuex';

import LoginModule from './modules/login';
import UsersModule from './modules/users';
import AreasModule from './modules/areas';
import NotificationsModule from './modules/notifications';
import PasswordPolicyModule from './modules/passwordPolicies';
import LocaleModule from './modules/locale';
import MenuModule from './modules/menu';
import TrackingModule from './modules/tracking-store';
import ShortcutsModule from './modules/shortcuts';
import initializeLocalesPlugin from './plugins/initialize_locales';
import createPersistentLoginState from './plugins/persistent_login_state';
import fileShare from './modules/fileshare';
import rolegroups from './modules/rolegroups';
import DatasetsModule from './modules/datasets';
import ImpersonationModule from './modules/impersonation';

//TODO: Journey-composer import appmodule from '../utils/webpack-component-context';

export default createStore({
  strict: true,
  plugins: [createPersistentLoginState(), initializeLocalesPlugin],
  modules: {
    login: LoginModule,
    impersonation: ImpersonationModule,
    areas: AreasModule,
    users: UsersModule,
    shortcuts: ShortcutsModule,
    notifications: NotificationsModule,
    passwordPolicies: PasswordPolicyModule,
    locale: LocaleModule,
    rolegroups: rolegroups,
    menu: MenuModule,
    // maps: MapsModule, // <-- to be provided by module
    fileShare: fileShare,
    tracking: TrackingModule,
    datasets: DatasetsModule,
    //...appmodule('./store').default,
  },
});
