import { Cookie } from '../../utils/cookie';
import { nextTick } from 'vue';
import { createCrossTabEventBus } from '../../utils/crosstabeventbus';
class CookieStorage {
    constructor(namePrefix = '') {
        this.namePrefix = namePrefix;
        this.domain = document.location.hostname;
    }
    get(key) {
        if (!document.cookie) {
            return undefined;
        }
        key = this._toCookieName(key);
        const cookie = document.cookie
            .split('; ')
            .map(cookie => Cookie.ParseMinimal(cookie))
            .find(cookie => cookie.name === key);
        if (!cookie) {
            return undefined;
        }
        return cookie.value;
    }
    set(key, value) {
        key = this._toCookieName(key);
        //https://stackoverflow.com/a/24103596
        //Don't set an expiredate - make our cookie expire along with the closing of our browser
        const cookie = new Cookie(key, value);
        cookie.domain = this.domain;
        cookie.sameSite = 'Lax'; //Only include cookie when navigating to current site
        cookie.secure = true;
        if (value === undefined || value === null) {
            cookie.expire();
        }
        document.cookie = cookie.toString();
    }
    _toCookieName(key) {
        return `${this.namePrefix}${key}`;
    }
}
/**
 * Create a plugin for persisting login state over reloads and syncing between multiple tabs
 *
 * @return {(store: Store<any>) => void}
 */
export default function createPersistentLoginState() {
    var _a;
    const customerMetaElement = document.querySelector('meta[name="customer"]');
    let customerName = (_a = customerMetaElement.getAttribute('content')) !== null && _a !== void 0 ? _a : customerMetaElement.getAttribute('value');
    if (customerName === '${customer_name}') {
        customerName = 'DEV';
    }
    const cookiePrefix = `DNX.${customerName}.`;
    const eventBus = createCrossTabEventBus('persisted_login');
    const storage = new CookieStorage(cookiePrefix);
    return (store) => {
        //Setup callback when token was updated from another tab
        //Depending on the browser(?) cookies may take an undefined amount of time to sync between tabs - therefore we can't use
        //our storage as a source of truth upon receiving our broadcast message
        //https://bugzilla.mozilla.org/show_bug.cgi?id=1331680#c0
        eventBus.subscribe('tokenUpdated', token => {
            //Our token may have changed in another tab
            //if so, update our token
            if (store.state.login.token === token) {
                return;
            }
            if (token) {
                store.commit('login/setToken', token);
            }
            else {
                //No token = logged out
                store.dispatch('login/logout');
            }
        });
        //Our storage is shared across tabs - thus our storage should be considered valid upon initialization
        //we may assume our storage is up to date upon initializing
        const storageToken = storage.get('token');
        if (storageToken) {
            store.commit('login/setToken', storageToken);
            nextTick(() => {
                // ensure we're actually logged in...
                if (store.getters['login/isExpiredToken']) {
                    store.dispatch('login/logout');
                    return;
                }
                store.dispatch('users/loadCurrentUser');
            });
        }
        //Force page reload post token update on impersonation, in our current state we don't have sufficient mechanisms set in place
        //to properly register + automatically reload user dependant data presented on the page
        store.watch((_, getters) => getters['impersonation/isImpersonating'], () => window.location.reload());
        //Always broadcast all setToken mutations across all open tabs
        store.subscribe(mutation => {
            if (mutation.type === 'login/setToken') {
                storage.set('token', mutation.payload);
                eventBus.emit('tokenUpdated', mutation.payload);
                //Ensure we have a user loaded on token change
                nextTick(() => {
                    store.dispatch('users/loadCurrentUser');
                });
            }
        });
    };
}
