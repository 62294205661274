var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from '@app/../app/api-generated/dataEntities';
const GET_INITIAL_STATE = () => ({
    datasets: [],
});
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    getters: {
        getDatasets: (state) => state.datasets,
    },
    actions: {
        fetchDatasets(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield api.index();
                commit('setDatasets', response.value);
            });
        },
        activate(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, id) {
                if ((yield api.activate(id)).success) {
                    commit('toggleDataEntityActive', { id, active: true });
                }
            });
        },
        deactivate(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, id) {
                if ((yield api.deactivate(id)).success) {
                    commit('toggleDataEntityActive', { id, active: false });
                }
            });
        },
    },
    mutations: {
        setDatasets(state, value) {
            if (value) {
                const result = value.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
                //DEV: Server does not yet support type, so add default here.
                result.forEach(item => (item.type = 'MSSql'));
                state.datasets = result;
            }
        },
        toggleDataEntityActive(state, { id, active }) {
            state.datasets = state.datasets.map(entity => {
                if (entity.id === id) {
                    entity.isActive = active;
                }
                return entity;
            });
        },
    },
};
