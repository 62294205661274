import {createResetMutation} from '@app/store/util/reset_state';
import users from '@app/../app/api-generated/users';
import themes from '@app/../app/api-generated/themes';

const GET_INITIAL_STATE = () => ({
  appThemes: undefined,
  userConfiguration: undefined,
});

export default {
  namespaced: true,
  state: GET_INITIAL_STATE(),
  getters: {
    getAppThemes: state => state.appThemes,
    getUserConfiguration: state => state.userConfiguration,
    activeThemeName: (state, _, __, rootGetters) => {
      const selectedThemeId = state.userConfiguration?.themeId;

      const loggedIn = rootGetters['login/loggedIn'];

      const getDefaultTheme = () => 'light';

      return (
        localStorage.getItem('appTheme') ||
        (selectedThemeId && state.appThemes?.find(x => x.id == selectedThemeId)?.name) ||
        getDefaultTheme()
      );
    },
  },
  actions: {
    async fetchAppThemes({commit, state}) {
      if (state.appThemes === undefined) {
        const response = await themes.get();
        commit('setAppThemes', response.value);
      }
    },
    async fetchUserConfiguration({commit, state}) {
      if (state.userConfiguration === undefined) {
        const response = await users.getUserConfiguration();
        commit('setUserConfiguration', response.value);
      }
    },
  },
  mutations: {
    reset: createResetMutation(GET_INITIAL_STATE),
    setAppThemes(state, data) {
      state.appThemes = data;
    },
    setUserConfiguration(state, data) {
      state.userConfiguration = data;
    },
  },
};
