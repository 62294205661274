import { addToast } from '@dnx/core';
//This store is deprecated, please use the addToast (from dnx/core)
export default {
    namespaced: true,
    actions: {
        triggerAlertMessage({ commit }, data) {
            var _a;
            //Mod(ify our incoming data, in case of a single message in our messages collection
            //and no primary message, hoist our single message upwards and make it our primary message
            if (!data.message && ((_a = data.messages) === null || _a === void 0 ? void 0 : _a.length) === 1)
                data.message = data.messages.pop();
            addToast(data.type, data.message);
        },
    },
    mutations: {
        setAlertMessages(state, data) {
            if (length in data) {
                data.forEach(x => addToast(x.message));
            }
            else {
                addToast(data.message);
            }
        },
    },
};
