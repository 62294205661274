import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
/** Simple store managing state of a global tooltip instance, consumed from core */
const useTooltip = defineStore('tooltip', () => {
    const body = shallowRef('');
    const show = shallowRef(false);
    const x = shallowRef(0);
    const y = shallowRef(0);
    return {
        body: body,
        show: show,
        x: x,
        y: y,
    };
});
/** Initialize the underlying pinia store */
export function initialize() { useTooltip(); }
export { useTooltip };
