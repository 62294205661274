import { useConfirmStore } from '../confirmation';
import { T } from '../../utils/i18n';
export const DefaultButton = {
    ok: {
        get name() {
            return T('JOURNEY_REVIEW_CONFIRM');
        },
        buttonStyle: 'white',
        handler: () => {
            useConfirmStore().$reset();
        },
    },
    yes: {
        get name() {
            return T('YES');
        },
        buttonStyle: 'primary-success',
        iconName: 'check-circle',
        handler: () => {
            return;
        },
    },
    no: {
        get name() {
            return T('NO');
        },
        buttonState() {
            return null;
        },
        buttonStyle: 'primary-danger',
        iconName: 'delete',
        handler: () => {
            useConfirmStore().$reset();
        },
    },
    delete: {
        get name() {
            return T('DELETE');
        },
        buttonState() {
            return null;
        },
        buttonStyle: 'primary-danger',
        iconName: 'delete',
        handler: () => {
            useConfirmStore().$reset();
        },
    },
    cancel: {
        get name() {
            return T('JOURNEY_REVIEW_CANCEL');
        },
        buttonState() {
            return null;
        },
        buttonStyle: 'white',
        handler: () => {
            useConfirmStore().$reset();
        },
    },
    close: {
        get name() {
            return T('CLOSE');
        },
        buttonStyle: 'primary-danger',
        handler: () => {
            return;
        },
    },
};
export const defaults = {
    ok: [DefaultButton.ok],
    cancel: [DefaultButton.cancel],
    yes: [DefaultButton.yes],
    yesno: [DefaultButton.yes, DefaultButton.no],
};
