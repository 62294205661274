// Is the object empty (null,undefined,empty array, empty guid, empty string, etc)
export function isEmpty(id) {
    var _a;
    if (undefined === id)
        return true;
    if (null === id)
        return true;
    if ('00000000-0000-0000-0000-000000000000' === id)
        return true;
    if (typeof id === 'string')
        return '' === id;
    if (Array.isArray(id))
        return 0 === ~~id.length;
    if (id instanceof Map)
        return id.size === 0;
    if (id instanceof Set)
        return id.size === 0;
    if (typeof id === 'object')
        return ~~((_a = Object.keys(id)) === null || _a === void 0 ? void 0 : _a.length) === 0;
    return false;
}
/** Pick a selection of fields from an object Ex: for(f in pickFrom({a,b,c:2}, 'a', 'c') console.log(f)) prints a,2*/
export function* pickFrom(obj, ...fields) {
    if (isEmpty(obj) || isEmpty(fields)) {
        return;
    }
    for (const field of fields) {
        yield obj[field];
    }
}
/** New object with just fields */
export function pick(obj, ...fields) {
    if (isEmpty(obj) || isEmpty(fields)) {
        return {};
    }
    let result = {};
    for (const field of fields)
        if (!isEmpty(obj[field]))
            result[field] = obj[field];
    return result;
}
// Create a slug from a string. slug('appeltaart',6) => 'ap..rt'
export function slug(value, len) {
    if (typeof value !== 'string' || value.length <= len || len < 4)
        return value;
    const lhs = Math.floor((len - 1) / 2);
    return `${value.substring(0, lhs)}..${value.substring(value.length - (len - 2 - lhs))}`;
}
