import areas from '@app/../app/api-generated/areas';
import usersApi from '@app/../app/api-generated/users';

export default {
  namespaced: true,
  state: {
    /**
     * @type {Object} Object representing root area and its children.
     */
    areas: null,
    summaries: null,

    /**
     * @type {Object} The selected area.
     */
    selectedArea: null,

    /**
     * @type {String} Selected area id
     */
    selectedAreaId: null,

    /**
     * @type {Object<String, Object>} Object containing keyValuePairs of user id, Users
     */
    selectedAreaUsers: {},
  },
  getters: {
    getAreas: state => state.areas,
    getAreasFlattened: state => {
      if (!state.areas) return [];

      let flattened = [];
      const flattenHelper = area => {
        flattened.push(area);

        if (area.children && area.children.length > 0) {
          for (const child of area.children) {
            flattenHelper(child);
          }
        }
      };

      if (Array.isArray(state.areas)) {
        for (const area of state.areas) {
          flattenHelper(area);
        }
      } else {
        flattenHelper(state.areas);
      }
      return flattened;
    },
    getSummaries: state => state.summaries,
    getSelectedArea: state => state.selectedArea,
    getSelectedAreaId: state => state.selectedAreaId,
    getSelectedAreaUsers: state => state.selectedAreaUsers,
  },
  actions: {
    /**
     * Fetch all areas from api
     * @param {String} id The id of the root area. (optional)
     */
    async fetchAreas({commit, getters}) {
      const response = await areas.get();
      commit('setAreas', response.value);
    },
    async fetchSummaries({commit, getters}) {
      if (getters.getSummaries) return;
      const response = await areas.getSummaries();
      commit('setSummaries', response.success ? response.value : []);
    },
    async fetchSelectedArea({commit}, id) {
      const response = await areas.getById(id);
      const area = response.value;

      commit('setSelectedArea', area);
      commit('setSelectedAreaId', area.id);
    },
    /**
     * Load users for area from api/cache.
     * @param {String} areaId The id of the area.
     */
    async loadAreaUsers({commit}, areaId) {
      const users = await usersApi.getByAreaId(areaId);
      commit('setUsers', users.value);
      commit('setSelectedAreaId', areaId);
    },
    async updateArea({state}) {
      const model = {...state.area, miscellaneous: ''};
      await areas.put(model);
    },

    /**
     * Change a property in the state's area object.
     * @param {Object} payload payload containing the name of the target prop and the intended value.
     */
    changeProp({commit}, payload) {
      commit('setProp', payload);
    },

    async deleteUser(ctx, userId) {
      const userIdx = (Array.isArray(ctx.state.selectedAreaUsers) ? ctx.state.selectedAreaUsers : []).findIndex(
        u => u.id === userId
      );

      const response = await usersApi.delete(userId);
      if (!response.success) throw response.value?.message ?? response.message;

      if (userIdx === -1) return;

      //Notify rest of our system of user removal
      const usersCopy = Array.from(ctx.state.selectedAreaUsers);
      usersCopy.splice(userIdx, 1);
      ctx.commit('setUsers', usersCopy);
    },

    async setUserEnabled(ctx, payload) {
      const {id, isEnabled} = payload;

      const response = await usersApi.setEnabled(id, isEnabled);
      if (!response.success) throw response.value?.message ?? response.message;

      const userIdx = (Array.isArray(ctx.state.selectedAreaUsers) ? ctx.state.selectedAreaUsers : []).findIndex(
        u => u.id === id
      );

      if (userIdx === -1) return;

      //Notify rest of our system of user update
      const usersCopy = Array.from(ctx.state.selectedAreaUsers);
      usersCopy[userIdx] = {...usersCopy[userIdx], isEnabled: isEnabled};
      ctx.commit('setUsers', usersCopy);
    },
  },
  mutations: {
    /**
     * Set Areas in current state.
     * @param {Array<Object>} value Array containing areas.
     */
    setAreas(state, value) {
      if (value) {
        state.areas = value;
      }
    },
    setSummaries: (state, data) => (state.summaries = data),

    /**
     * set the users for the currently selected area.
     * @param {Array<Object>} users Array of users.
     */
    setUsers(state, users) {
      // Set users in to store.
      state.selectedAreaUsers = users;
    },

    /**
     * Set id of currently selecterd area.
     * @param {String} id id to set.
     */
    setSelectedAreaId(state, id) {
      state.selectedAreaId = id;
    },

    /**
     * Set selected area.
     * @param {Object} area area to set.
     */
    setSelectedArea(state, area) {
      state.selectedArea = area;
    },

    /**
     * Set Areas in current state.
     * @param {Array<Object>} value Area.
     */
    setArea(state, value) {
      // value.miscellaneous = JSON.parse(value.miscellaneous);
      state.area = value;
    },
    setProp(state, {name}) {
      state.area[name] = name;
    },
  },
};
