var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from 'pinia';
/** Simple one-time signal alerting either completion or cancellation, can be awaited */
class Signal {
    constructor() {
        this.complete = () => { };
        this.cancel = () => { };
        this._promise = new Promise((resolve, reject) => {
            this.complete = () => {
                resolve();
            };
            this.cancel = () => {
                reject();
            };
        });
        // disable unhandled exception from bubbling
        // https://jakearchibald.com/2023/unhandled-rejections/
        this._promise.catch(() => { });
        this.then = this._promise.then.bind(this._promise);
    }
    static Delay(ms) {
        const signal = new Signal();
        setTimeout(() => signal.complete(), ms);
        return signal;
    }
}
// NTH: Use cacheapi or tanstack, but low level generated code uses dnxresponse, not the data. For now we return re-streamable response proxies.
class CacheItem {
    constructor() {
        this._signal = new Signal();
        this.data = undefined;
        this.headers = new Map();
    }
    setData(obj) {
        this.data = obj;
        this._signal.complete();
    }
    setHeaders(headers) {
        [...headers].forEach(([k, v]) => this.headers.set(k, v));
    }
    get ok() {
        return true;
    }
    get status() {
        return 200;
    }
    get statusText() {
        return 'From cache';
    }
    json() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._signal;
            return this.data;
        });
    }
    text() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._signal;
            return this.data;
        });
    }
}
CacheItem.Aborted = {
    ok: false,
    status: 200,
    statusText: 'aborted',
    headers: new Map().set('content-type', 'application/json'),
    json: () => null,
};
export const useQueryStore = defineStore('query', {
    state: () => {
        // temporary, should handle caching in webworker -> set up messaging based API (tell api to fetch data -> ready when ready)
        // alternative solution: requestHandlerPipeline.addMiddleware( queryStoreMiddleware )
        queueMicrotask(() => (Window.runtime.query = useQueryStore()));
        const cache = new Map();
        const abort = new AbortController();
        return { cache, abort };
    },
    actions: {
        /** Purge a set of entries based on a part of their key */
        purge(locatorPart) {
            // full key was passed
            if (this.cache.has(locatorPart)) {
                const key = locatorPart;
                // console.log('[CACHE]: Purging: ' + key);
                this.cache.get(key)._signal.cancel();
                this.cache.delete(key);
                return;
            }
            // key part was passed, wipe all that matches
            for (const [key, entry] of this.cache.entries()) {
                if (!key.includes(locatorPart))
                    continue;
                // console.log('[CACHE]: Purging: ' + key);
                entry._signal.cancel();
                this.cache.delete(key);
            }
        },
        createKey(path, request) {
            // use btoa due to lack of sync SubtleCrypto.digest
            return `${path}:${request.method}:${btoa(typeof request.body === 'string' ? request.body : '')}`;
        },
        fetch(path, request) {
            const dontCache = (path, request) => {
                const body = typeof request.body === 'string' && request.body[0] === '{' && JSON.parse(request.body);
                // Create calls go to api/widgets!
                if (path.indexOf('widgets/') < 0 && path.indexOf('dashboard') < 0)
                    return true;
                // widget update call
                if (path.indexOf('widgets/') >= 0 && (body === null || body === void 0 ? void 0 : body.update))
                    return true;
                // dashboard exports
                if (path.indexOf('export') >= 0)
                    return true;
                // skip previewdata calls, data shape depends on saved configuration
                if (path.indexOf('widgets/') >= 0 && path.indexOf('previewData') >= 0)
                    return true;
                if (request.method !== 'GET' && request.method !== 'POST')
                    return true;
                return false;
            };
            const anySignal = (signals) => {
                // remove undefined
                signals = signals.filter(s => s);
                // not yet supported in firefox/safari
                if (AbortSignal.any)
                    return AbortSignal.any(signals);
                const anyController = new AbortController();
                for (const signal of signals) {
                    signal.addEventListener('abort', abort => anyController.abort(abort.target.reason));
                }
                return anyController.signal;
            };
            if (dontCache(path, request))
                return window.fetch(path, request);
            const queryStore = useQueryStore();
            const cacheKey = this.createKey(path, request);
            const id = path;
            if (queryStore.cache.has(cacheKey)) {
                // console.log('[CACHE]: Hit! ' + cacheKey);
                const cacheitem = queryStore.cache.get(cacheKey);
                // @ts-ignore
                return cacheitem._signal.then(() => cacheitem, () => CacheItem.Aborted);
            }
            // console.log('[CACHE]: No hit! ' + cacheKey);
            const cacheitem = new CacheItem();
            queryStore.cache.set(cacheKey, cacheitem);
            // @ts-ignore
            return window
                .fetch(path, Object.assign(Object.assign({}, request), { signal: anySignal([queryStore.abort.signal, request.signal]) }))
                .then((resp) => __awaiter(this, void 0, void 0, function* () {
                const response = yield resp.json();
                cacheitem.setData(response);
                cacheitem.setHeaders(resp.headers);
                return cacheitem;
            }))
                .catch(() => {
                console.log(`Purge:${cacheKey}`);
                this.purge(cacheKey);
                // @ts-ignore happens after....
                //Window.runtime?.store?.commit('widgets/setDeleteWidgetDataKey', 'fc229ce2-fc20-44c8-80b6-c1c854722ed1');
                return CacheItem.Aborted;
            });
        },
    },
});
