<template>
  <ul>
    <li v-for="reason in props.data.reasons" :key="reason">
      <XParagraph componentStyle="md" color="var(--text-primary-500)">
        {{ reason }}
      </XParagraph>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  data: {},
});
</script>

<style scoped>
ul {
  list-style: none;
}
</style>
