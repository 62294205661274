import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-2af696b5"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = { class: "progress-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XIcon = _resolveComponent("XIcon");
    const _component_n_progress = _resolveComponent("n-progress");
    const _component_XStack = _resolveComponent("XStack");
    const _component_XParagraph = _resolveComponent("XParagraph");
    const _component_XTooltipNaive = _resolveComponent("XTooltipNaive");
    return ($setup.progressIndicators.length)
        ? (_openBlock(), _createBlock(_component_XStack, {
            key: 0,
            class: "indicators-wrapper",
            direction: "vertical",
            alignment: $props.collapsedView ? 'center' : 'start'
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.progressIndicators, (progressIndicator, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "indicator-wrapper",
                        onMouseenter: _cache[0] || (_cache[0] = ($event) => ($setup.hover = true)),
                        onMouseleave: _cache[1] || (_cache[1] = ($event) => ($setup.hover = false))
                    }, [
                        _createVNode(_component_XTooltipNaive, {
                            title: progressIndicator.title,
                            alignment: "top-start",
                            padding: "sm",
                            componentStyle: "black",
                            style: { "min-width": "14rem" }
                        }, {
                            header: _withCtx(() => [
                                _createTextVNode(_toDisplayString(progressIndicator.title), 1)
                            ]),
                            content: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(progressIndicator.tasks, (task, index) => {
                                    return (_openBlock(), _createBlock(_component_XStack, {
                                        key: index,
                                        direction: "horizontal",
                                        alignment: "between",
                                        gap: "xs",
                                        class: "progress-tooltip"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_XParagraph, {
                                                color: task.textColor
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(task.caption), 1)
                                                ]),
                                                _: 2
                                            }, 1032, ["color"]),
                                            _createVNode(_component_XParagraph, {
                                                color: task.textColor
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(task.statusText), 1)
                                                ]),
                                                _: 2
                                            }, 1032, ["color"])
                                        ]),
                                        _: 2
                                    }, 1024));
                                }), 128))
                            ]),
                            default: _withCtx(() => [
                                _createElementVNode("div", {
                                    onClick: ($event) => (progressIndicator.supportsDetails && $setup.toggleOpen(progressIndicator.group)),
                                    style: { "cursor": "pointer" }
                                }, [
                                    _createVNode(_component_XStack, {
                                        class: "bar-wrapper",
                                        gap: "sm",
                                        padding: "xs",
                                        crossAlignment: "center",
                                        alignment: $props.collapsedView ? 'center' : 'start'
                                    }, {
                                        default: _withCtx(() => [
                                            (!$props.collapsedView)
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _createVNode(_component_XIcon, {
                                                        name: progressIndicator.icon,
                                                        class: "progress-bar-icon",
                                                        color: $setup.hover ? 'var(--sideBar-selectedItemColor)' : '#A8CDE8',
                                                        size: "xs"
                                                    }, null, 8, ["name", "color"]),
                                                    _createElementVNode("div", _hoisted_2, [
                                                        _createElementVNode("div", {
                                                            class: "progress-bar",
                                                            style: _normalizeStyle({
                                                                '--progress-bar': progressIndicator.color,
                                                                width: progressIndicator.progress + '%',
                                                            })
                                                        }, null, 4)
                                                    ])
                                                ], 64))
                                                : (_openBlock(), _createBlock(_component_n_progress, {
                                                    key: 1,
                                                    type: "circle",
                                                    percentage: progressIndicator.progress,
                                                    style: { "padding": "0 var(--spacing-sm)", "min-width": "2.75rem" },
                                                    "stroke-width": $setup.hover ? 15 : 10,
                                                    color: progressIndicator.color,
                                                    "rail-color": "var(--sideBar-backgroundColor)"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_XIcon, {
                                                            name: progressIndicator.icon,
                                                            class: "progress-bar-icon",
                                                            color: $setup.hover ? 'var(--sideBar-selectedItemColor)' : '#A8CDE8',
                                                            size: "xs"
                                                        }, null, 8, ["name", "color"])
                                                    ]),
                                                    _: 2
                                                }, 1032, ["percentage", "stroke-width", "color"]))
                                        ]),
                                        _: 2
                                    }, 1032, ["alignment"])
                                ], 8, _hoisted_1)
                            ]),
                            _: 2
                        }, 1032, ["title"])
                    ], 32));
                }), 128))
            ]),
            _: 1
        }, 8, ["alignment"]))
        : _createCommentVNode("", true);
}
