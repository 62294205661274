import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_XIcon = _resolveComponent("XIcon");
    const _component_XText = _resolveComponent("XText");
    const _component_XButton = _resolveComponent("XButton");
    const _component_XPopup = _resolveComponent("XPopup");
    return ($setup.current)
        ? (_openBlock(), _createBlock(_component_XPopup, {
            key: 0,
            title: $setup.current.options.title,
            closeIcon: $setup.current.options.closeIcon,
            showBackground: $setup.current.options.foreground,
            onClose: _cache[0] || (_cache[0] = ($event) => { var _a, _b; return (((_b = (_a = $setup.current).closeHandler) === null || _b === void 0 ? void 0 : _b.call(_a)) || $setup.current.close()); }),
            ref: "popupElement"
        }, _createSlots({
            default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent($setup.current.options.body)))
            ]),
            _: 2
        }, [
            ((_a = $setup.current.options.buttons) === null || _a === void 0 ? void 0 : _a.length)
                ? {
                    name: "footer",
                    fn: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.current.options.buttons, (button) => {
                            return (_openBlock(), _createBlock(_component_XButton, {
                                componentStyle: button.style,
                                color: button.color,
                                variant: button.variant,
                                state: $setup.isExecuting(button) ? 'loading' : undefined,
                                onClick: ($event) => ($setup.execute(button))
                            }, {
                                default: _withCtx(() => [
                                    (button.icon)
                                        ? (_openBlock(), _createBlock(_component_XIcon, {
                                            key: 0,
                                            name: button.icon.name || button.icon,
                                            color: button.icon.color
                                        }, null, 8, ["name", "color"]))
                                        : _createCommentVNode("", true),
                                    _createVNode(_component_XText, null, {
                                        default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(button.label), 1)
                                        ]),
                                        _: 2
                                    }, 1024)
                                ]),
                                _: 2
                            }, 1032, ["componentStyle", "color", "variant", "state", "onClick"]));
                        }), 256))
                    ]),
                    key: "0"
                }
                : undefined
        ]), 1032, ["title", "closeIcon", "showBackground"]))
        : _createCommentVNode("", true);
}
