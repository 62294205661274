var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from 'pinia';
import { DefaultButton, defaults } from './types/Button';
import { T } from '@dnx/core';
export const useConfirmStore = defineStore('confirmStore', {
    state: () => ({
        showConfirmBox: false,
        box: {
            title: '',
            status: 'information',
            buttons: [],
            actions: {},
        },
        defaultButton: DefaultButton,
    }),
    actions: {
        toggleConfirmBox() {
            this.showConfirmBox = !this.showConfirmBox;
        },
        updateBoxProperties(payload) {
            this.box = Object.assign(Object.assign({}, this.box), payload);
            this.toggleConfirmBox();
        },
        prompt(title, message, status, buttons) {
            return __awaiter(this, void 0, void 0, function* () {
                return new Promise(resolve => {
                    this.$reset();
                    this.updateBoxProperties({
                        title,
                        message,
                        status,
                        buttons: [
                            buttons && buttons.denyButton
                                ? Object.assign(Object.assign({}, (typeof buttons.denyButton === 'string'
                                    ? Object.assign(Object.assign({}, DefaultButton.cancel), { name: buttons.denyButton }) : buttons.denyButton)), { handler: () => {
                                        this.showConfirmBox = false;
                                        return resolve(false);
                                    } }) : Object.assign(Object.assign({}, DefaultButton.cancel), { name: T('NO'), handler: () => {
                                    this.showConfirmBox = false;
                                    return resolve(false);
                                } }),
                            buttons && buttons.confirmButton
                                ? Object.assign(Object.assign({}, (typeof buttons.confirmButton === 'string'
                                    ? Object.assign(Object.assign({}, DefaultButton.yes), { name: buttons.confirmButton }) : buttons.confirmButton)), { handler: () => {
                                        this.showConfirmBox = false;
                                        return resolve(true);
                                    } }) : Object.assign(Object.assign({}, DefaultButton.yes), { name: T('YES'), handler: () => {
                                    this.showConfirmBox = false;
                                    return resolve(true);
                                } }),
                        ],
                    });
                    this.showConfirmBox = true;
                });
            });
        },
        promptDelete(title, message) {
            return __awaiter(this, void 0, void 0, function* () {
                return this.prompt(title, message, 'error', {
                    denyButton: DefaultButton.cancel,
                    confirmButton: DefaultButton.delete,
                });
            });
        },
        confirm(title, message, config) {
            return __awaiter(this, void 0, void 0, function* () {
                return new Promise(resolve => {
                    this.updateBoxProperties({
                        title,
                        message,
                        status: (config === null || config === void 0 ? void 0 : config.status) || 'success',
                        buttons: [
                            Object.assign(Object.assign({}, DefaultButton.ok), { name: T('OK'), handler: () => {
                                    this.showConfirmBox = false;
                                    return resolve();
                                } }),
                        ],
                    });
                });
            });
        },
        setButtons(buttons) {
            this.box.buttons = buttons;
        },
        // going to be removed as it can be replaced with $reset;
        clearBox() {
            this.box.title = '';
            this.box.status = 'information';
            this.box.componentName = undefined;
            this.box.message = undefined;
            this.box.footNote = undefined;
            this.box.data = undefined;
            this.box.icon = undefined;
            this.box.buttons = [];
        },
        showMessage(text) {
            this.updateBoxProperties({
                title: 'Information',
                message: text,
                buttons: defaults.ok,
                status: 'information',
            });
            this.toggleConfirmBox();
        },
    },
});
