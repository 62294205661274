var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from 'pinia';
import { nextTick } from 'vue';
import notificationsApi from '../../../api/src/api-generated/notifications';
import { isEmpty } from '../utils/util';
import { addToast } from '@dnx/core';
import { useProgressService } from "./progress";
function reportProgress(payload) {
    /*
     * Payload anatomy:
     *  group (module w/e?, journey, etc.)
     *  title (again, module or w/e, can make this a translatable)
     *
     *  task (individual worklist, do thing)
     *  state ( --> optional?, running, in progress, completed, etc. )
     *  caption ( --> tooltip?, display some extra info )
     *
     *  pingAfter/interval/pollInterval/shouldPoll... ? --> when to schedule next call in case of no activity
     *
     *
     * maybe include callback url/list of descriptors at a later point in time? -> manual cancellation, entity navigation, etc.
     */
    useProgressService().push({
        group: payload.group,
        task: payload.task,
        title: payload.title,
        id: payload.id,
        state: payload.state,
        current: payload.current,
        total: payload.total,
        progress: payload.progress,
        // optional
        caption: payload.caption,
        statusText: payload.statusText,
    });
}
export const HEADER = 'x-notifications-latestnotificationtimestamp';
const useServerMessagesService = defineStore('server-messages', {
    state: () => ({
        lastId: 0,
        ticket: undefined,
        pending: null,
        listeners: [],
    }),
    actions: {
        initialize() {
            /* Turn this on to see all notifcations as UI notficiations
            this.subscribe( 'mangl',
              function notify (payload) {
                addToast('information',`[${~~((+new Date() % 1e6) / 1000)}] ${payload.conversationId}:${payload.status}`)
              }
            )
            */
            this.subscribe('system', function notify(payload) {
                const { type, message } = payload;
                switch (type) {
                    case 'notify':
                        addToast('information', message);
                        break;
                    case 'progress':
                        reportProgress(payload);
                        break;
                }
            });
        },
        subscribe(type, callback) {
            const id = this.lastId++;
            this.listeners.push({ id, type, notify: callback });
            return () => (this.listeners = this.listeners.filter(x => x.id === id));
        },
        publish(message) {
            this.listeners
                .filter(x => x.type === message.type)
                .forEach(x => {
                try {
                    x.notify(message.payload);
                }
                catch (e) {
                    console.error(e);
                }
            });
        },
        setTicket(value) {
            if (this.ticket !== value) {
                nextTick(this.fetchMessages.bind(this));
            }
        },
        fetchMessages() {
            return __awaiter(this, arguments, void 0, function* (force = false) {
                if (!force && this.pending === this.ticket)
                    return;
                this.pending = this.ticket;
                const response = yield notificationsApi.get(this.ticket);
                function safe(value) {
                    try {
                        return JSON.parse(value);
                    }
                    catch (_a) {
                        return {};
                    }
                }
                const messages = (response.success ? response.value : [])
                    .map(n => ({ id: n.id, type: n.type || n.notificationType, payload: safe(n.message) }))
                    .filter(m => !isEmpty(m.payload));
                this.ticket = response.headers[HEADER];
                messages.forEach(msg => this.publish(msg));
            });
        },
    },
});
export default useServerMessagesService;
