import Shortcuts from '@app/../app/api-generated/shortcuts';
const actions = {
    /**
     * Track the creation of an individual entity
     * @param ctx Vuex context
     * @param {EntityCreatedOrViewedPayload} data Payload describing created entity and how to construct a link on how to view the entity
     */
    trackEntityCreation(ctx, data) {
        const currentUser = ctx.rootState.users.currentUser;
        //No current user loaded yet - ignore event
        //events are bound to specific users, without a user our event is worthless
        if (!currentUser) {
            return;
        }
        Shortcuts.createNewRecentlyCreatedShortcut({
            module: data.module,
            routeInfo: {
                name: data.route.name,
                queryParameters: data.route.query,
                routeParameters: data.route.params,
            },
            metadata: data.metadata,
        });
    },
    /**
     * Track the viewing of an individual entity
     * @param ctx Vuex context
     * @param {EntityCreatedOrViewedPayload} data Payload describing viewed entity and how to construct a link on how to view the entity
     */
    trackEntityView(ctx, data) {
        const currentUser = ctx.rootState.users.currentUser;
        //No current user loaded yet - ignore event
        //events are bound to specific users, without a user our event is worthless
        if (!currentUser) {
            return;
        }
        Shortcuts.createNewRecentlyViewedShortcut({
            module: data.module,
            routeInfo: {
                name: data.route.name,
                queryParameters: data.route.query,
                routeParameters: data.route.params,
            },
            metadata: data.metadata,
        });
    },
};
export default {
    namespaced: true,
    actions,
};
