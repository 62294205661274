import { nextTick, shallowRef, triggerRef } from 'vue';
import { defineStore } from 'pinia';
/** General entrypoint for triggering display of arbitrary components outside of Vue context */
export const usePopupService = defineStore('popupService', () => {
    var _a;
    const current = shallowRef();
    /** Show at a specific location with the given options */
    function showAt(options) {
        create(options);
        // wait for popup to be created in the DOM
        nextTick(() => { var _a; return (_a = current.value) === null || _a === void 0 ? void 0 : _a.moveToElement(options); });
    }
    /** Show a popup in the center of the screen with the given options */
    function create(options) {
        // fixme: assign identifier, allow stacking of popups
        // eg. while popupA is being shown, allow popupA to create popupB. Upon closing A, B would subsequently be automatically closed
        current.value = {
            options: options,
            update(options) {
                current.value.options.buttons = options.buttons;
                // notify renderer of change without making everything fully reactive
                triggerRef(current);
            },
            close() {
                current.value = undefined;
            },
            closeHandler: options.closeHandler,
            moveToElement(options) {
                var _a, _b, _c;
                const target = typeof options.target === 'string' ? document.querySelector(options.target) : options.target;
                if (!target)
                    return;
                (_c = (_b = (_a = current.value) === null || _a === void 0 ? void 0 : _a.ref) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.moveToElement(target, options.position, options.alignment);
            },
        };
        return current.value;
    }
    (_a = current.value) === null || _a === void 0 ? void 0 : _a.ref;
    return {
        create,
        showAt,
        // targeted at internal use, should not be directly modified by outside parties (make proxy, warn when tampered?)
        current,
    };
});
