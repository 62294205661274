var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class RoleGroup {
    constructor() {
        this.paths = Object.freeze({
            createGroup: (name) => {
                const __query = new QueryStringBuilder().add("name", name);
                return `api/RoleGroup/CreateGroup${__query.toString()}`;
            },
            removeGroup: (groupid) => {
                const __query = new QueryStringBuilder().add("groupId", groupid);
                return `api/RoleGroup/RemoveGroup${__query.toString()}`;
            },
            getGroups: () => `api/RoleGroup/Groups`,
            getRoles: () => `api/RoleGroup/Roles`,
            getGroupDetails: (groupid) => {
                const __query = new QueryStringBuilder().add("groupId", groupid);
                return `api/RoleGroup/Details${__query.toString()}`;
            },
            addGroupUsers: (groupid) => {
                const __query = new QueryStringBuilder().add("groupId", groupid);
                return `api/RoleGroup/AddGroupUsers${__query.toString()}`;
            },
            removeGroupUsers: (groupid) => {
                const __query = new QueryStringBuilder().add("groupId", groupid);
                return `api/RoleGroup/DeleteGroupUsers${__query.toString()}`;
            },
            updateGroupRoles: (groupid) => {
                const __query = new QueryStringBuilder().add("groupId", groupid);
                return `api/RoleGroup/UpdateGroupRoles${__query.toString()}`;
            },
        });
    }
    /**
     * RoleGroup.CreateGroup "POST api/RoleGroup/CreateGroup"
     * @param {string} name
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<RoleGroupModel>>}
     */
    createGroup(name, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("name", name);
            let path = `api/RoleGroup/CreateGroup${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.RemoveGroup "POST api/RoleGroup/RemoveGroup"
     * @param {Guid} groupid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    removeGroup(groupid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("groupId", groupid);
            let path = `api/RoleGroup/RemoveGroup${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.GetGroups "GET api/RoleGroup/Groups"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<RoleGroupModel[]>>}
     */
    getGroups(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/RoleGroup/Groups`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.GetRoles "GET api/RoleGroup/Roles"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<RoleModel[]>>}
     */
    getRoles(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/RoleGroup/Roles`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.GetGroupDetails "GET api/RoleGroup/Details"
     * @param {Guid} groupid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<RoleGroupDetailModel>>}
     */
    getGroupDetails(groupid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("groupId", groupid);
            let path = `api/RoleGroup/Details${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.AddGroupUsers "POST api/RoleGroup/AddGroupUsers"
     * @param {Guid} groupid
     * @param {Guid[]} userids
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    addGroupUsers(groupid, userids, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("groupId", groupid);
            let path = `api/RoleGroup/AddGroupUsers${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, userids);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.RemoveGroupUsers "POST api/RoleGroup/DeleteGroupUsers"
     * @param {Guid} groupid
     * @param {Guid[]} users
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    removeGroupUsers(groupid, users, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("groupId", groupid);
            let path = `api/RoleGroup/DeleteGroupUsers${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, users);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * RoleGroup.UpdateGroupRoles "POST api/RoleGroup/UpdateGroupRoles"
     * @param {Guid} groupid
     * @param {RoleGroupRoleModel[]} roles
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    updateGroupRoles(groupid, roles, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("groupId", groupid);
            let path = `api/RoleGroup/UpdateGroupRoles${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, roles);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const roleGroup = new RoleGroup();
export { roleGroup as default, RoleGroup };
