/**
 * @callback getInitialState
 * @returns {Object} initial state of the Vuex store
 */

/**
 * @param {getInitialState|Object} initialState
 * @returns {function(Object)} Mutation used for resetting Vuex state
 */
const createResetMutation = initialState => {
  return state => {
    resetState(state, initialState);
  };
};

/**
 * @param {Object} state Current Vuex state
 * @param {getInitialState|Object} initialState Function or object containing initial state
 */
const resetState = (state, initialState) => {
  const initialStateValue = typeof initialState === 'function' ? initialState() : initialState;

  //Preserve state reactiveness by setting our keys one by one
  Object.keys(initialStateValue).forEach(stateKey => {
    state[stateKey] = initialStateValue[stateKey];
  });
};

export {createResetMutation};

export default resetState;
