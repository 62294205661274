import { breakpoints } from '@dnx/core';
export const registerBreakpointDirectives = (app) => {
    Object.entries(breakpoints).forEach(entry => {
        const breakpoint = entry[0];
        const value = entry[1];
        app.directive(`show-${breakpoint}`, {
            mounted(el) {
                const mediaQuery = window.matchMedia(`(min-width: ${value}px)`);
                const updateVisibility = () => {
                    if (mediaQuery.matches) {
                        el.style.removeProperty('display');
                    }
                    else {
                        el.style.display = 'none';
                    }
                };
                updateVisibility();
                mediaQuery.addEventListener('change', updateVisibility);
            },
        });
        app.directive(`hide-${breakpoint}`, {
            mounted(el) {
                const mediaQuery = window.matchMedia(`(max-width: ${value}px)`);
                const updateVisibility = () => {
                    if (mediaQuery.matches) {
                        el.style.removeProperty('display');
                    }
                    else {
                        el.style.display = 'none';
                    }
                };
                updateVisibility();
                mediaQuery.addEventListener('change', updateVisibility);
            },
        });
    });
};
