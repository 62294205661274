import menuApi from '@app/../app/api-generated/menu';

const GET_INITIAL_STATE = () => ({
  /**
   * @type {Array<MenuItem>} Array containing menu items
   */
  menuItems: [],
});

export default {
  namespaced: true,
  state: GET_INITIAL_STATE(),
  mutations: {
    setMenuItems(state, value) {
      state.menuItems = value;
    },
  },
  actions: {
    /**
     * Load all menu items for the current logged in user
     * @return {Promise<void>}
     */
    async loadMenuItems({commit}) {
      const apiMenuItemsResult = await menuApi.getMenuItemsForCurrentUser();

      commit('setMenuItems', apiMenuItemsResult.value);
    },
  },
};
