var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import impersonationApi from '@app/../app/api-generated/impersonation';
import { DnxJwtToken } from '@app/store/models/jwt';
import { addToast } from '@dnx/core';
//ImpersonationService?
const getters = {
    /**
     * Is the current user being impersonated by another user?
     * @param state
     * @param getters
     * @param rootState
     * @return {boolean}
     */
    isImpersonating(state, getters, rootState) {
        return DnxJwtToken.parse(rootState.login.token).isImpersonating;
    },
};
const actions = {
    /**
     * Start an impersonation session of the given user
     * @param ctx
     * @param userId
     * @return {Promise<void>}
     */
    startImpersonation(ctx, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            const response = yield impersonationApi.start(userId);
            if (!response.success) {
                const message = (_b = (_a = response.value) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : response.message;
                addToast('error', message);
                return;
            }
            ctx.commit('login/setToken', response.value.token, { root: true });
            //Refresh current page after impersonation, to make sure everything is displaying properly
            window.location.reload();
        });
    },
    /**
     * Stop the current impersonation session, does nothing when not in an impersonation session
     * @param ctx
     * @return {Promise<void>}
     */
    stopImpersonation(ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (!ctx.getters.isImpersonating)
                return;
            const response = yield impersonationApi.stop();
            if (!response.success) {
                const message = (_b = (_a = response.value) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : response.message;
                addToast('error', message);
                return;
            }
            ctx.commit('login/setToken', response.value.token, { root: true });
            //Refresh current page after impersonation, to make sure everything is displaying properly
            window.location.reload();
        });
    },
};
const module = {
    namespaced: true,
    getters,
    actions,
};
export default module;
