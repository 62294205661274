var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class Account {
    constructor() {
        this.paths = Object.freeze({
            getSummaries: () => `api/account/summaries`,
            self: () => `api/account/self`,
            deleteLinkedUser: (sourceid, destinationid) => {
                const __query = new QueryStringBuilder().add("sourceId", sourceid).add("destinationId", destinationid);
                return `api/account/DeleteLinkedUser${__query.toString()}`;
            },
            patchToggleLinkedUserHidden: (destinationid, ishidden) => {
                const __query = new QueryStringBuilder().add("destinationId", destinationid).add("isHidden", ishidden);
                return `api/account/PatchToggleLinkedUserHidden${__query.toString()}`;
            },
            getLinkedUsers: () => `api/account/getLinkedUsers`,
            resetTfa: (id) => `api/account/resetTfa/${encodeURIComponent(id)}`,
        });
    }
    /**
     * Account.GetSummaries "GET api/account/summaries"
     *
     * Get a flattened list of all users in the current user's area and below.
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<UserSummaryModel[]>>}
     */
    getSummaries(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/account/summaries`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Account.Self "GET api/account/self"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    self(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/account/self`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Account.DeleteLinkedUser "DELETE api/account/DeleteLinkedUser"
     * @param {Guid} sourceid
     * @param {Guid} destinationid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    deleteLinkedUser(sourceid, destinationid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("sourceId", sourceid).add("destinationId", destinationid);
            let path = `api/account/DeleteLinkedUser${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Account.PatchToggleLinkedUserHidden "PATCH api/account/PatchToggleLinkedUserHidden"
     * @param {Guid} destinationid
     * @param {Boolean} ishidden
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    patchToggleLinkedUserHidden(destinationid, ishidden, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("destinationId", destinationid).add("isHidden", ishidden);
            let path = `api/account/PatchToggleLinkedUserHidden${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Account.GetLinkedUsers "GET api/account/getLinkedUsers"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<LinkedUserListModel[]>>}
     */
    getLinkedUsers(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/account/getLinkedUsers`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Account.ResetTfa "PUT api/account/resetTfa/{id}"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    resetTfa(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/account/resetTfa/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const account = new Account();
export { account as default, Account };
