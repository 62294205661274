import {createResetMutation} from '@dnx/core';

import passwordPolicyApi from '@app/../app/api-generated/passwordPolicy';

const GET_INITIAL_STATE = () => ({
  /**
   * @type PasswordPolicyListItemModel[]
   */
  passwordPolicies: [],
  currentUserPolicy: null,
  activePolicy: null,

  /** @type {null | Date} */
  lastActivePolicyLoad: null,
});

export default {
  namespaced: true,
  state: GET_INITIAL_STATE(),
  getters: {
    getPasswordPolicies: state => state.passwordPolicies,
    getCurrentUserPolicy: state => state.currentUserPolicy,
    getActivePolicy: state => state.activePolicy,
  },
  mutations: {
    reset: createResetMutation(GET_INITIAL_STATE),
    /**
     * @param state
     * @param {PasswordPolicyListItemModel[]} policies
     */
    setPasswordPolicies(state, policies) {
      state.passwordPolicies = policies;
    },
    /**
     * @param state
     * @param {PasswordPolicyModel} policy
     */
    setCurrentUserPolicy(state, policy) {
      state.currentUserPolicy = policy;
    },
    /**
     * @param state
     * @param {PasswordPolicyModel} policy
     */
    setActivePolicy(state, policy) {
      state.activePolicy = policy;
    },

    /**
     * @param state
     */
    markLastActivePolicyLoad(state) {
      state.lastActivePolicyLoad = new Date();
    },
  },
  actions: {
    async loadAllPasswordPolicies({commit}) {
      const policies = (await passwordPolicyApi.getAllPasswordPolicies()).value;
      commit('setPasswordPolicies', policies);
    },
    async GetUserPasswordPolicy({commit}, email) {
      const policy = (await passwordPolicyApi.getUserEffectivePasswordPolicyByEmail(email)).value;
      commit('setCurrentUserPolicy', policy);
    },
    async loadActivePolicy({commit, state}) {
      const now = new Date();

      // throttle to only be invoked every 5 minutes
      if (state.lastActivePolicyLoad) {
        const secondsSinceLastLoad = (now - state.lastActivePolicyLoad) / 1000;
        if (secondsSinceLastLoad < 300) return;
      }

      commit('markLastActivePolicyLoad');
      const policy = (await passwordPolicyApi.getCurrentEffectivePasswordPolicy()).value;
      commit('setActivePolicy', policy);
    },
  },
};
