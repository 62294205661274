import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
export const useEditorStore = defineStore('editor', () => {
    const prolongedUserActivity = ref(false);
    const enforceConfirmLeave = ref(false);
    const enforceSkipConfirmation = ref(false);
    const registeredEditors = ref([]);
    const registerEditor = (ref) => {
        registeredEditors.value = [...registeredEditors.value, ref];
    };
    const unregisterEditor = (ref) => {
        registeredEditors.value = registeredEditors.value.filter(x => x != ref);
    };
    const hasDirtyEditors = computed(() => registeredEditors.value.some(x => x.value));
    const showWarning = (e) => {
        e.preventDefault();
        e.returnValue = '';
    };
    watch(hasDirtyEditors, value => {
        window[value ? 'addEventListener' : 'removeEventListener']('beforeunload', showWarning);
    });
    return {
        prolongedUserActivity,
        enforceConfirmLeave,
        enforceSkipConfirmation,
        registerEditor,
        unregisterEditor,
        hasDirtyEditors,
    };
});
