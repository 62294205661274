import authApi from '@app/../app/api-generated/authentication';
import passwordPolicyApi from '@app/../app/api-generated/passwordPolicy';
import {T} from '@app/utils/i18n';
import {MissingPropertyError} from '@app/utils/errors';

const requireProperties = (state, ...properties) => {
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    if (!state[property]) {
      throw new MissingPropertyError(property);
    }
  }
};

export default {
  namespaced: true,
  state: {
    /**
     * @type {String}
     */
    email: undefined,

    /**
     * Password reset token
     * @type {String}
     */
    changePasswordToken: undefined,

    /**
     * Password reset validation code
     * @type {String}
     */
    changePasswordValidationCode: undefined,

    /**
     * New password to use for user
     * @type {String}
     */
    newPassword: '',

    /**
     * Password policy associated with the given user
     * @type {PasswordPolicyModel}
     */
    passwordPolicy: undefined,
  },
  getters: {
    getEmail: state => state.email,
    getNewPassword: state => state.newPassword,
    getChangePasswordToken: state => state.changePasswordToken,
    getChangePasswordValidationCode: state => state.changePasswordValidationCode,
    getPasswordPolicy: state => state.passwordPolicy,
  },
  mutations: {
    setEmail(state, value) {
      state.email = value;
    },
    setChangePasswordToken(state, value) {
      state.changePasswordToken = value;
    },
    setChangePasswordValidationCode(state, value) {
      state.changePasswordValidationCode = value;
    },
    setNewPassword(state, value) {
      state.newPassword = value;
    },
    setPasswordPolicy(state, value) {
      state.passwordPolicy = value;

      // code for testing purposes, since our current password policy doesn't contain
      // any applicable
      //   state.passwordPolicy = {
      //     ...value,
      //     minLength: 5,
      //     minSpecialChars: 1,
      //     minEngUpperCase: 1,
      //     minEngLowerCase: 1,
      //     minNumbers: 1,
      //     minSpecialChars: 1,
      //   };
    },
  },
  actions: {
    /**
     * Initialize forgot password with the given token
     * @param dispatch
     * @param commit
     * @param {string} token Change password token
     * @return {Promise<void>}
     */

    async initWithToken({dispatch, commit}, {token, code}) {
      const response = await authApi.getUserByChangePasswordToken(token);

      //Alert our consumer of our failure
      if (!response.success) throw response.value?.message || response.message;

      commit('setEmail', response.value.email);
      commit('setChangePasswordToken', token);
      commit('setChangePasswordValidationCode', code);

      //Awaiting should not be necessary
      dispatch('loadPasswordPolicy');
    },

    /**
     * Load the password policy associated with the given email
     * @param state
     * @param commit
     * @return {Promise<void>}
     */
    async loadPasswordPolicy({state, commit}) {
      if (!state.email) {
        return;
      }

      commit('setPasswordPolicy', (await passwordPolicyApi.getUserEffectivePasswordPolicyByEmail(state.email)).value);
    },

    /**
     * Request a password change for the given email
     * @param {Object} state
     */
    async requestPasswordChange({state, commit}) {
      requireProperties(state, 'email');

      const {success, value} = await authApi.requestPasswordChange({
        email: state.email,
      });
      if (!success) throw value;

      commit('setChangePasswordToken', value);
    },

    /**
     * Reset the password for the user with the given code
     * @param {Object} state
     */
    async resetPassword({state}) {
      requireProperties(state, 'email', 'changePasswordValidationCode', 'newPassword');

      const {success, value, message} = await authApi.confirmPasswordChange({
        email: state.email,
        validationCode: state.changePasswordValidationCode,
        newPassword: state.newPassword,
      });

      if (!success) throw value?.message || message;
    },
    async fetchPasswordPoliciesByEmailAndCode({state, commit}) {
      const response = await passwordPolicyApi.getUserEffectivePasswordPolicyByEmailAndCode(
        state.email,
        state.changePasswordValidationCode
      );
      let value = response.value;

      if (response.success) {
        commit('setPasswordPolicy', value);
      } else {
        if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
          value = JSON.parse(value);
        }

        if (value.status && value.status === 401) {
          throw T('ERROR_CHANGE_PASSWORD_VALIDATION_CODE_INVALID');
        } else {
          if (Array.isArray(value)) {
            throw value.map(message => message.message);
          } else {
            throw value.message || value;
          }
        }
      }
    },
  },
};
