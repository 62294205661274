var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class PasswordPolicy {
    constructor() {
        this.paths = Object.freeze({
            getAllPasswordPolicies: () => `api/PasswordPolicy/GetAllPasswordPolicies`,
            getCurrentEffectivePasswordPolicy: () => `api/PasswordPolicy/GetCurrentEffectivePasswordPolicy`,
            getUserEffectivePasswordPolicyByEmail: (email) => {
                const __query = new QueryStringBuilder().add("email", email);
                return `api/PasswordPolicy/GetUserEffectivePasswordPolicyByEmail${__query.toString()}`;
            },
            getUserEffectivePasswordPolicyByEmailAndCode: (email, code) => {
                const __query = new QueryStringBuilder().add("email", email).add("code", code);
                return `api/PasswordPolicy/GetUserEffectivePasswordPolicyByEmailAndCode${__query.toString()}`;
            },
            getAreaBasedEffectivePasswordPolicy: (areaid, passwordpolicyid) => {
                const __query = new QueryStringBuilder().add("areaId", areaid).add("passwordPolicyId", passwordpolicyid);
                return `api/PasswordPolicy/GetAreaBasedEffectivePasswordPolicy${__query.toString()}`;
            },
        });
    }
    /**
     * PasswordPolicy.GetAllPasswordPolicies "GET api/PasswordPolicy/GetAllPasswordPolicies"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<PasswordPolicyModel[]>>}
     */
    getAllPasswordPolicies(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/PasswordPolicy/GetAllPasswordPolicies`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * PasswordPolicy.GetCurrentEffectivePasswordPolicy "GET api/PasswordPolicy/GetCurrentEffectivePasswordPolicy"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<PasswordPolicyModel>>}
     */
    getCurrentEffectivePasswordPolicy(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/PasswordPolicy/GetCurrentEffectivePasswordPolicy`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * PasswordPolicy.GetUserEffectivePasswordPolicyByEmail "GET api/PasswordPolicy/GetUserEffectivePasswordPolicyByEmail"
     * @param {string} email
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<PasswordPolicyModel>>}
     */
    getUserEffectivePasswordPolicyByEmail(email, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("email", email);
            let path = `api/PasswordPolicy/GetUserEffectivePasswordPolicyByEmail${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * PasswordPolicy.GetUserEffectivePasswordPolicyByEmailAndCode "GET api/PasswordPolicy/GetUserEffectivePasswordPolicyByEmailAndCode"
     * @param {string} email
     * @param {string} code
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<PasswordPolicyModel>>}
     */
    getUserEffectivePasswordPolicyByEmailAndCode(email, code, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("email", email).add("code", code);
            let path = `api/PasswordPolicy/GetUserEffectivePasswordPolicyByEmailAndCode${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getUnauthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * PasswordPolicy.GetAreaBasedEffectivePasswordPolicy "GET api/PasswordPolicy/GetAreaBasedEffectivePasswordPolicy"
     * @param {Guid} areaid
     * @param {Guid} passwordpolicyid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<PasswordPolicyModel>>}
     */
    getAreaBasedEffectivePasswordPolicy(areaid, passwordpolicyid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("areaId", areaid).add("passwordPolicyId", passwordpolicyid);
            let path = `api/PasswordPolicy/GetAreaBasedEffectivePasswordPolicy${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const passwordPolicy = new PasswordPolicy();
export { passwordPolicy as default, PasswordPolicy };
