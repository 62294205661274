var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore, getActivePinia } from 'pinia';
import { DnxJwtToken } from '../store/models/jwt';
import { useConfirmStore } from './confirmation';
import { isEmpty } from '../utils/util';
import { T } from '@dnx/core';
class User {
    /** Create a new user instance */
    constructor(jwt) {
        // todo: read firstname/lastname from jwt, requires backend expansion
        // this.firstName = model.firstName
        // this.lastName = model.lastName
        // add all claims as part of the current user
        Object.assign(this, DnxJwtToken.parse(jwt).claims);
        this.token = jwt;
        this.tasks = []; /* Signout issues? */
        this.logoutController = new AbortController();
        this.logoutSignal = this.logoutController.signal;
        this.taskCleanupHandlers = new WeakMap();
        this.logoutSignal.addEventListener('abort', () => {
            for (const task of this.tasks) {
                for (const cleanup of this.taskCleanupHandlers.get(task)) {
                    cleanup();
                }
            }
        });
    }
    resolveTask(task) {
        const idx = this.tasks.indexOf(task);
        if (idx !== -1)
            this.tasks.splice(idx, 1);
    }
    addTask(label) {
        const issue = {
            label,
            addCleanup: (cleanup) => {
                this.taskCleanupHandlers.get(issue).push(cleanup);
                return issue;
            },
        };
        this.taskCleanupHandlers.set(issue, []);
        this.tasks.push(issue);
        return issue;
    }
}
// request processing middleware
function signRequest(request) {
    if (!Window.runtime.user || !Window.runtime.user.token)
        return;
    request.headers = Object.assign(Object.assign({}, request.headers), { ['Authorization']: `Bearer ${Window.runtime.user.token}` });
}
/** Display a prompt to the user confirming they want to logout when pending tasks have been detected */
function promptUnresolvedTasks(confirmStore) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (isEmpty((_a = Window.runtime.user) === null || _a === void 0 ? void 0 : _a.tasks))
            return Promise.resolve(true);
        let secondsRemaining = 10;
        return new Promise(resolve => {
            let timeoutId = undefined;
            confirmStore
                .prompt(T('AUTHENTICATION_UNRESOLVED_TASKS_PROMPT_TITLE', { secondsRemaining }), '', 'warning')
                .then(choice => resolve(choice))
                .catch(() => resolve(true))
                .finally(() => clearTimeout(timeoutId));
            // Hijack display method, force a nodelist instead instead of a simple message
            confirmStore.box.componentName = 'NodeList';
            confirmStore.box.data = {
                nodes: [...new Set(Window.runtime.user.tasks.map((x) => x.label))],
            };
            // force signout after X seconds
            timeoutId = setTimeout(function updateTitle() {
                const pendingTasks = [
                    ...new Set(Window.runtime.user.tasks.map((x) => x.label)),
                ];
                // prevent close on update..
                confirmStore.showConfirmBox = true;
                if (--secondsRemaining === 0) {
                    confirmStore.$reset();
                    resolve(true);
                    return;
                }
                // all pending tasks resolved, decrease countdown if possible
                if (isEmpty(Window.runtime.user.tasks))
                    secondsRemaining = Math.min(secondsRemaining, 2);
                confirmStore.box.title = T('AUTHENTICATION_UNRESOLVED_TASKS_PROMPT_TITLE', { secondsRemaining });
                confirmStore.box.data = {
                    nodes: isEmpty(pendingTasks) ? T('AUTHENTICATION_UNRESOLVED_TASKS_PROMPT_ALL_RESOLVED_MESSAGE') : pendingTasks,
                };
                timeoutId = setTimeout(updateTitle, 1000);
            }, 1000);
        });
    });
}
export const useAuthentication = defineStore('authentication', () => {
    const confirmStore = useConfirmStore();
    // We should start migrating auth functionality to this store
    // auth process should be removed from Vuex
    // auth object in app-full, logout + login, bevat ready state
    // user object wordt gegenereerd bij binnenkrijgen geldig token
    // hub etc. hangen aan user
    // jwt token claims expanden naar properties op user
    // signoutissues (oid) in user
    // try to login, restore state
    function logout() {
        return __awaiter(this, void 0, void 0, function* () {
            // prompt, give ~5-10 seconds to respond no answer = logout
            // should also trigger this on impersonation
            var _a;
            if (!(yield promptUnresolvedTasks(confirmStore)))
                return false;
            // cancel user bound requests
            Window.runtime.user.logoutController.abort('Logout');
            // clear our user
            Window.runtime.user = undefined;
            // reset all pinias
            const pinia = getActivePinia();
            if ((_a = pinia === null || pinia === void 0 ? void 0 : pinia.state) === null || _a === void 0 ? void 0 : _a.value) {
                // https://github.com/vuejs/pinia/discussions/911#discussioncomment-5009286
                for (const storeName of Object.keys(pinia.state.value)) {
                    const store = defineStore(storeName, {});
                    try {
                        // @ts-ignore
                        if (store().$_reset)
                            store().$_reset();
                        // allow options based stores to still define a custom reset routine
                        else if (store().$reset)
                            store().$reset();
                    }
                    catch (e) {
                        /* Swallow */
                    }
                }
            }
            return true;
        });
    }
    function login(jwt) {
        Window.runtime.user = new User(jwt);
    }
    return {
        /** Logout the current user */
        logout,
        /** Login the current user */
        login,
    };
});
