var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import RoleGroup from '@app/../app/api-generated/roleGroup';
const GET_INITIAL_STATE = () => ({
    availableGroups: [],
    availableRoles: [],
    selectedGroup: null,
});
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    mutations: {
        updateGroups(state, { groups }) {
            state.availableGroups = groups;
        },
        updateRoles(state, { roles }) {
            state.availableRoles = roles;
        },
        updateSelectedGroup(state, { group }) {
            state.selectedGroup = group;
        },
        updateGroupRole(state, { roleId, canRead, canWrite }) {
            const existingIndex = state.selectedGroup.roles.findIndex(x => x.roleId === roleId);
            if (typeof canRead === 'undefined' && typeof canWrite === 'undefined') {
                if (existingIndex >= 0) {
                    state.selectedGroup.roles.splice(existingIndex, 1);
                }
            }
            else {
                if (existingIndex >= 0) {
                    state.selectedGroup.roles[existingIndex].canRead =
                        typeof canRead !== 'undefined' ? (canRead ? true : null) : state.selectedGroup.roles[existingIndex].canRead;
                    state.selectedGroup.roles[existingIndex].canWrite =
                        typeof canWrite !== 'undefined'
                            ? canWrite
                                ? true
                                : null
                            : state.selectedGroup.roles[existingIndex].canWrite;
                }
                else {
                    const groupRole = { roleId };
                    groupRole.canRead = canRead;
                    groupRole.canWrite = canWrite;
                    state.selectedGroup.roles.push(groupRole);
                }
            }
        },
    },
    actions: {
        fetchGroups(context) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield RoleGroup.getGroups();
                if (result.success) {
                    context.commit('updateGroups', { groups: result.value });
                }
            });
        },
        fetchRoles(context) {
            return __awaiter(this, void 0, void 0, function* () {
                const result = yield RoleGroup.getRoles();
                if (result.success) {
                    context.commit('updateRoles', { roles: result.value });
                }
            });
        },
        fetchGroupDetails(context_1, _a) {
            return __awaiter(this, arguments, void 0, function* (context, { groupId }) {
                const result = yield RoleGroup.getGroupDetails(groupId);
                if (result.success) {
                    context.commit('updateSelectedGroup', { group: result.value });
                }
            });
        },
        saveGroup(context) {
            return __awaiter(this, void 0, void 0, function* () {
                const group = context.state.selectedGroup;
                const roles = group.roles.filter(x => typeof x.canRead !== 'undefined' || typeof x.canWrite !== 'undefined');
                yield RoleGroup.updateGroupRoles(group.id, roles);
            });
        },
    },
};
