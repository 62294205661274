var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class DataEntities {
    constructor() {
        this.paths = Object.freeze({
            activate: (id) => `api/dataentities/${encodeURIComponent(id)}/activate`,
            deactivate: (id) => `api/dataentities/${encodeURIComponent(id)}/deactivate`,
            setTesting: (id) => `api/dataentities/${encodeURIComponent(id)}/setTesting`,
            unsetTesting: (id) => `api/dataentities/${encodeURIComponent(id)}/unsetTesting`,
            getDivisions: (id) => `api/dataentities/${encodeURIComponent(id)}/getDivisions`,
            getInheritedDivisions: (id) => `api/dataentities/${encodeURIComponent(id)}/getInheritedDivisions`,
            setDivisions: (id) => `api/dataentities/${encodeURIComponent(id)}/setDivisions`,
            index: () => `api/dataentities`,
        });
    }
    /**
     * DataEntities.Activate "PATCH api/dataentities/{id}/activate"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    activate(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/activate`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.Deactivate "PATCH api/dataentities/{id}/deactivate"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    deactivate(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/deactivate`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.SetTesting "PATCH api/dataentities/{id}/setTesting"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    setTesting(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/setTesting`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.UnsetTesting "PATCH api/dataentities/{id}/unsetTesting"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    unsetTesting(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/unsetTesting`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.GetDivisions "GET api/dataentities/{id}/getDivisions"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<Guid[]>>}
     */
    getDivisions(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/getDivisions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.GetInheritedDivisions "GET api/dataentities/{id}/getInheritedDivisions"
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<Guid[]>>}
     */
    getInheritedDivisions(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/getInheritedDivisions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.SetDivisions "PATCH api/dataentities/{id}/setDivisions"
     * @param {Guid} id
     * @param {SetDivisionDto} divisionids
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<void>>}
     */
    setDivisions(id, divisionids, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities/${id === undefined || id === null ? "" : encodeURIComponent(id)}/setDivisions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PATCH");
            jsonRequestBodyWriter.write(request, divisionids);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * DataEntities.Index "GET api/dataentities"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<DataEntity[]>>}
     */
    index(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/dataentities`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const dataEntities = new DataEntities();
export { dataEntities as default, DataEntities };
