var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { defineStore } from 'pinia';
import { cleanKey } from '@dnx/core';
import { watch } from 'vue';
import { debounce } from 'lodash';
const percentage = (current, total) => total === 0 // prevent against 0 division
    ? 100
    : Math.min(Math.round((current / total) * 100), 100);
const sum = (values) => values.reduce((current, value) => current + value, 0);
const average = (values) => {
    // prevent division by 0
    const total = sum(values);
    if (total === 0)
        return 0;
    return Math.round(total / values.length);
};
/** Object representing progress state + extra information belonging to said state */
class ProgressState {
    constructor(name, color, textColor) {
        this.name = cleanKey(name);
        this.color = color;
        this.textColor = textColor;
        _a.lookup[this.name] = this;
    }
    /** Check whether one instance equals another instance */
    equals(other) {
        if (typeof other === 'string')
            other = _a.resolve(other);
        return other.name === this.name;
    }
    /** Resolve a state by name */
    static resolve(name) {
        return _a.lookup[cleanKey(name)] || _a.Initial;
    }
    /** Infer a state to represent the cumulative set of given states */
    static infer(states) {
        const running = states.filter(x => x.equals(_a.Running));
        const completed = states.filter(x => x.equals(_a.Completed));
        const cancelled = states.filter(x => x.equals(_a.Cancelled) || // single item
            x.equals(_a.AllCancelled) || // all underlying items
            x.equals(_a.SomeCancelled) // some underlying items
        );
        if (completed.length === states.length)
            return _a.Completed;
        else if (cancelled.length === states.length && !cancelled.some(x => x.equals(_a.SomeCancelled)))
            return _a.AllCancelled;
        else if (cancelled.length >= 1)
            return _a.SomeCancelled;
        return running.length >= 1 ? _a.Running : _a.Initial;
    }
}
_a = ProgressState;
ProgressState.lookup = {};
(() => {
    _a.Initial = new _a('initial', 'var(--main-primary, #2783C6)', '#ffffff');
    _a.Running = new _a('running', 'var(--main-primary, #2783C6)', '#ffffff');
    _a.Cancelled = new _a('cancelled', 'var(--main-warning, #F1A400)', 'var(--main-warning, #F1A400)');
    _a.SomeCancelled = new _a('some-cancelled', 'var(--main-warning, #F1A400)', 'var(--main-warning, #F1A400)');
    _a.AllCancelled = new _a('all-cancelled', 'var(--main-error, #D21460)', 'var(--main-error, #D21460)');
    _a.Completed = new _a('completed', 'var(--main-success, #00B866)', 'var(--main-success, #00B866)');
})();
const compress = (data) => __awaiter(void 0, void 0, void 0, function* () {
    const stream = new Blob([data], {
        type: 'application/json',
    }).stream();
    const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
    const compressedResponse = yield new Response(compressedReadableStream);
    const blob = yield compressedResponse.blob();
    const buffer = yield blob.arrayBuffer();
    // convert ArrayBuffer to base64 encoded string
    const compressedBase64 = btoa(String.fromCharCode(...new Uint8Array(buffer)));
    return compressedBase64;
});
const decompress = (base64String) => __awaiter(void 0, void 0, void 0, function* () {
    const compressedUint8Array = new Uint8Array(atob(base64String)
        .split('')
        .map(char => char.charCodeAt(0)));
    const stream = new Blob([compressedUint8Array], {
        type: "application/json",
    }).stream();
    const compressedReadableStream = stream.pipeThrough(new DecompressionStream("gzip"));
    const resp = yield new Response(compressedReadableStream);
    const blob = yield resp.blob();
    return yield blob.text();
});
/** Simple typed wrapper around a single localstorage key, will prevent access violations etc. from bubbling */
class Storage {
    constructor(key) {
        this.key = key;
    }
    /** Persist the given value */
    write(value) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!value) {
                    window.localStorage.removeItem(this.key);
                    return;
                }
                const newStorageObject = yield compress(JSON.stringify(value, (key, value) => {
                    if (key === 'parent')
                        return undefined;
                    if (key === 'state')
                        return value.name;
                    return value;
                }));
                window.localStorage.setItem(this.key, newStorageObject);
            }
            catch (e) { }
        });
    }
    /** Reload the given value */
    read() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const value = window.localStorage.getItem(this.key);
                if (!value)
                    return {};
                const parsedData = yield decompress(value);
                const groups = JSON.parse(parsedData, (key, value) => {
                    if (key === 'state')
                        return ProgressState.resolve(value);
                    return value;
                });
                // fix parent relations
                for (const group of Object.values(groups)) {
                    for (const task of Object.values(group.tasks)) {
                        task.parent = group;
                        for (const subTask of Object.values(task.subTasks)) {
                            subTask.parent = task;
                        }
                    }
                }
                return groups;
            }
            catch (e) {
                return {};
            }
        });
    }
}
const storage = new Storage('dnx:progress');
// helpers:
const writeToStorage = debounce((groups) => { storage.write(groups), 0; });
// registerInitializer (oid) -> invoked during
const useProgressService = defineStore('progress', {
    // initialize from localstorage, request status per module
    state: () => ({
        groups: {},
        subtasks: {},
        tasks: {},
    }),
    actions: {
        $_reset() {
            writeToStorage({});
            this.groups = {};
        },
        updateGroups() {
            return __awaiter(this, void 0, void 0, function* () {
                this.groups = yield storage.read();
                const initialTasks = {};
                const initialSubtasks = {};
                this.groups && Object.values(this.groups).flatMap(x => Object.values(x.tasks)).forEach(task => {
                    initialTasks[task.id] = task;
                    task.subTasks && Object.values(task.subTasks).forEach((subtask) => {
                        initialSubtasks[subtask.id] = subtask;
                    });
                });
                this.tasks = initialTasks;
                this.subtasks = initialSubtasks;
            });
        },
        /** After initialization, ping providers asking for hydration of the progress items */
        initialize() {
            return __awaiter(this, void 0, void 0, function* () {
                //<-- invoke in initialize?
                yield this.updateGroups();
                for (const [name, group] of Object.entries(this.groups)) {
                    // should do this via bridge or something...
                    // const evt = 'dnx:progress-initialize:' + name;
                    // document.dispatchEvent(new CustomEvent(evt, { detail: { group: group } }));
                    // cancel all pending, will require acknowledgement prior to removal
                    this.cancel(name);
                }
            });
        },
        /** Destroy the contained state, fully reset any cached data */
        destroy() {
            this.groups = {};
            writeToStorage(this.groups);
        },
        /** Subscribe for changes in the subtask with the given id */
        subscribe(id, callback) {
            const subTask = this._find(id, 'subtask');
            if (subTask) {
                watch(() => subTask.state.name, callback);
            }
        },
        /**
         * Alias for update
         * @see update
         */
        push(update) {
            this.update(update);
        },
        /** Push a progress update, will affect all structures  */
        update(update) {
            for (const item of [update].flat()) {
                this._updateGroup(item);
                this._updateTask(item);
                this._updateSubTask(item);
            }
            // persist our progress
            writeToStorage(this.groups);
        },
        /** Remove the given item, when a task/group is emptied, the task/group will be deleted */
        remove(id) {
            for (const item of [id].flat()) {
                this._removeGroup(item);
                this._removeTask(item);
                this._removeSubtask(item);
            }
            writeToStorage(this.groups);
        },
        /** Mark all uncompleted items in a group, task, or subtask as completed */
        start(id) {
            for (const subTask of this._collect(id)) {
                this.update({
                    id: subTask.id,
                    // completed = final
                    state: subTask.state.equals(ProgressState.Initial) ? 'running' : undefined,
                });
            }
        },
        /** Mark all uncompleted items in a group, task, or subtask as completed */
        complete(id) {
            for (const subTask of this._collect(id)) {
                this.update({
                    id: subTask.id,
                    // completed = final
                    state: subTask.state.equals(ProgressState.Cancelled) ? undefined : 'completed',
                });
            }
        },
        /** Mark all uncompleted items in a group, task, or subtask as cancelled */
        cancel(id) {
            for (const subTask of this._collect(id)) {
                this.update({
                    id: subTask.id,
                    // completed = final
                    state: subTask.state.equals(ProgressState.Completed) ? undefined : 'cancelled',
                });
            }
        },
        /** @private */
        _removeGroup(id) {
            delete this.groups[id];
        },
        /** @private */
        _removeTask(id) {
            const task = this._find(id, 'task');
            if (!task)
                return;
            delete task.parent.tasks[id];
            // infer group state after task removal
            task.parent.state = ProgressState.infer(Object.values(task.parent.tasks).map(x => x.state));
            if (Object.values(task.parent.tasks).length === 0)
                this._removeGroup(task.parent.id);
        },
        /** @private */
        _removeSubtask(id) {
            const subTask = this._find(id, 'subtask');
            if (!subTask)
                return;
            delete subTask.parent.subTasks[id];
            if (Object.values(subTask.parent.subTasks).length === 0)
                this._removeTask(subTask.parent.id);
        },
        /** @private */
        _updateGroup(update) {
            var _b;
            var _c, _d;
            if (!update.group)
                return;
            const group = ((_b = (_c = this.groups)[_d = update.group]) !== null && _b !== void 0 ? _b : (_c[_d] = {
                id: update.group,
                title: update.group,
                tasks: {},
                state: ProgressState.Initial,
                progress: 0,
                isOpen: false,
            }));
            if (update.title)
                group.title = update.title;
        },
        /** @private */
        _updateTask(update) {
            if (!update.task)
                return;
            let task = this._find(update.task, 'task');
            // create task when possible
            if (!task && update.group) {
                const group = this._find(update.group, 'group');
                task = group.tasks[update.task] = {
                    id: update.task,
                    startTime: new Date(),
                    progress: 0,
                    statusText: '',
                    caption: '',
                    state: ProgressState.Initial,
                    subTasks: {},
                    parent: group,
                };
                this.tasks[task.id] = task;
            }
            // task not found, group not specified
            if (!task)
                return;
            if (update.statusText)
                task.statusText = update.statusText;
            if (update.caption)
                task.caption = update.caption;
        },
        /** @private */
        _updateSubTask(update) {
            var _b;
            if (!update.id)
                return;
            let subTask = update.task
                ? (_b = this._find(update.task, 'task')) === null || _b === void 0 ? void 0 : _b.subTasks[update.id]
                : this._find(update.id, 'subtask');
            // create sub task when possible
            if (!subTask && update.task) {
                const task = this._find(update.task, 'task');
                if (!task)
                    return;
                subTask = task.subTasks[update.id] = {
                    id: update.id,
                    progress: 0,
                    state: ProgressState.Initial,
                    parent: task,
                    detail: undefined,
                };
                this.subtasks[subTask.id] = subTask;
            }
            if (!subTask)
                return;
            if (update.detail)
                subTask.detail = update.detail;
            if (update.state) {
                subTask.state = ProgressState.resolve(update.state);
                // also update task state - task state is based upon underlying subtask states
                subTask.parent.state = ProgressState.infer(Object.values(subTask.parent.subTasks).map(x => x.state));
                subTask.parent.parent.state = ProgressState.infer(Object.values(subTask.parent.parent.tasks).map(x => x.state));
            }
            // assume completed = 100% completed
            let newProgress = undefined;
            if (Number.isFinite(update.total) && Number.isFinite(update.current))
                newProgress = percentage(update.current, update.total);
            if (Number.isFinite(update.progress))
                newProgress = Math.min(Math.abs(update.progress), 100);
            if (subTask.state.equals(ProgressState.Completed) || subTask.state.equals(ProgressState.Cancelled)) {
                newProgress = 100;
            }
            if (typeof newProgress !== 'undefined') {
                // also update our parents progress
                subTask.progress = newProgress;
                subTask.parent.progress = average(Object.values(subTask.parent.subTasks).map(x => x.progress));
                subTask.parent.parent.progress = average(Object.values(subTask.parent.parent.tasks).map(x => x.progress));
            }
        },
        /**
         * Collect all subtasks with id, or falling under the id
         * @private
         */
        _collect: function* (id) {
            const group = this._find(id, 'group');
            const task = this._find(id, 'task');
            const subTask = this._find(id, 'subtask');
            if (group) {
                for (const task of Object.values(group.tasks)) {
                    for (const subTask of Object.values(task.subTasks)) {
                        yield subTask;
                    }
                }
            }
            if (task) {
                for (const subTask of Object.values(task.subTasks)) {
                    yield subTask;
                }
            }
            if (subTask)
                yield subTask;
        },
        /**
         * Find the item of type {TType} with the given id
         * @private
         */
        _find(id, type) {
            if (type === 'group')
                return this.groups[id];
            if (type == 'task') {
                const task = this.tasks[id];
                return task;
            }
            if (type == 'subtask') {
                const subTask = this.subtasks[id];
                return subTask;
            }
            return undefined;
        },
    },
});
export { useProgressService };
