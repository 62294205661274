import {createI18n} from 'vue-i18n';
import i18next from 'i18next';
import dutchTranslation from 'zod-i18n-map/locales/nl/zod.json';
import englishTranslation from 'zod-i18n-map/locales/en/zod.json';
import {z} from 'zod';
import {makeZodI18nMap} from 'zod-i18n-map';

if (!window?._dnx?.defaultLocale?.id) {
  console.warn('Default locale missing. Old bootstrap');
  window._dnx ||= {};
  window._dnx.defaultLocale ||= {};
  window._dnx.defaultLocale.id = 'nl';
}

i18next.init({
  lng: 'nl',
  resources: {
    nl: {zod: dutchTranslation},
    en: {zod: englishTranslation},
  },
});

const messages = {
  [window._dnx.defaultLocale.id]: {
    ...window._dnx.defaultResources,
  },
};

function missingTranslation(_, key) {
  if (/[^A-Z_]/.test(key)) return key;

  window._t = window._t || {};
  if (!window._t[key]) {
    const s = key.replace(/_/g, ' ').trim();
    window._t[key] = s.length > 0 ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}` : '';
  }
  return window._t[key] || key;
}

export const i18n = createI18n({
  messages,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false,
  //dateTimeFormats,
  locale: window._dnx.defaultLocale.id,
  fallbackLocale: 'nl',
  missing: missingTranslation,
});

// Helper function to abstract out i18n dependency
const T = (value, placeholders, fallback = undefined) => {
  if (!i18n.global?.messages[i18n.global.locale][value]?.trim() && fallback) {
    return i18n.global?.t(fallback);
  } else {
    return i18n.global?.t(value, placeholders);
  }
};

z.setErrorMap(makeZodI18nMap({ns: 'zod', t: i18next.t}));

export {messages, i18next, T};
export default i18n;
