var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from 'pinia';
import securityApi from '../../app/api-generated/security';
import { computedAsync } from '@vueuse/core';
import { computed, ref } from 'vue';
/**
 * Enum representing the differing grant types for an individual permission
 */
export var PermissionGrantType;
(function (PermissionGrantType) {
    /**
     * Permission to see resources
     * @type {PermissionGrantType.Read}
     */
    PermissionGrantType[PermissionGrantType["Read"] = 1] = "Read";
    /**
     * Permission to altering existing resources
     * @type {PermissionGrantType.Write}
     */
    PermissionGrantType[PermissionGrantType["Write"] = 2] = "Write";
    /**
     * Permission for creating new resources
     * @type {PermissionGrantType.Create}
     */
    PermissionGrantType[PermissionGrantType["Create"] = 4] = "Create";
    /**
     * Permission to delete resources
     * @type {PermissionGrantType.Delete}
     */
    PermissionGrantType[PermissionGrantType["Delete"] = 8] = "Delete";
})(PermissionGrantType || (PermissionGrantType = {}));
export const dnxPermissions = {
    //<-- TODO: Generate from APIConsumerGenerator?
    Dashboarding: '3a7bf240-324c-4994-bfa4-eb92a85c2edd',
    WidgetThemes: '8e92b85b-9e90-407a-8b77-0b033426f2da',
    WidgetCustomQueries: 'e2705ca4-8032-4fcb-be06-7ab4e8f98f64',
    Users: '69D58517-2D06-4BED-8140-A7299798A2F5',
    ImpersonateUser: 'a3e38213-0845-406d-8777-8cfeed2e30ae',
    JourneyComposer: 'C631638B-067A-42ED-B9A1-9E273AEE0B45',
    JourneyComposerReview: '60832130-CD96-4BE4-8719-3FB9A49D4BA6',
    TfaAdmin: '78145e12-54f7-4b0b-8ee9-44b8b17f1d27',
    JourneyBypassReviews: '0581C040-C633-485F-AC5E-5E9823ECEC5B',
};
const dnxRoles = {
    dnxadmin: 'ed0dfe18-cfed-43a2-8bcc-b803b5f72705',
};
const normalizePermissionId = (permissionId) => {
    return permissionId.toLowerCase();
};
export const useSecurityStore = defineStore('securityStore', () => {
    const store = Window.runtime.store;
    const permissionCache = {
        get() {
            try {
                return JSON.parse(window.sessionStorage.getItem('dnx:permissions'));
            }
            catch (_a) {
                return undefined;
            }
        },
        set(value) {
            try {
                window.sessionStorage.setItem('dnx:permissions', JSON.stringify(value));
            }
            catch (_a) {
                return;
            }
        },
        clear() {
            try {
                window.sessionStorage.removeItem('dnx:permissions');
            }
            catch (_a) {
                return;
            }
        }
    };
    // wipe cache when starting/stopping impersonation/logging out
    store.subscribeAction(({ type }) => {
        if (type === 'impersonation/startImpersonation' ||
            type === 'impersonation/stopImpersonation' ||
            type === 'login/logout' ||
            type === 'login/setToken') {
            permissionCache.clear();
        }
    });
    const permissionsLoaded = ref(false);
    const rolesLoaded = ref(false);
    const isLoaded = computed(() => rolesLoaded.value && permissionsLoaded.value);
    const loggedIn = computed(() => store.getters['login/loggedIn']);
    const roles = computedAsync(() => __awaiter(void 0, void 0, void 0, function* () {
        rolesLoaded.value = false;
        if (!loggedIn.value) {
            rolesLoaded.value = true;
            return [];
        }
        const response = yield securityApi.getAllRoles();
        if (!response.success) {
            rolesLoaded.value = true;
            return [];
        }
        rolesLoaded.value = true;
        return response.value;
    }), []);
    const assignableRoles = computedAsync(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!loggedIn.value) {
            rolesLoaded.value = true;
            return [];
        }
        const response = yield securityApi.getAssignableRoles();
        if (!response.success)
            return;
        return response.value;
    }), []);
    const permissions = computedAsync(() => __awaiter(void 0, void 0, void 0, function* () {
        permissionsLoaded.value = false;
        if (!loggedIn.value) {
            permissionsLoaded.value = true;
            return new Map();
        }
        let permissions = permissionCache.get();
        if (!permissions) {
            const response = yield securityApi.getCurrentUserPermissions();
            if (!response.success) {
                permissionsLoaded.value = true;
                return new Map();
            }
            permissions = response.value;
            permissionCache.set(permissions);
        }
        const updatedPermissions = new Map(permissions.map(permissionModel => {
            return [permissionModel.permissionId, permissionModel];
        }));
        permissionsLoaded.value = true;
        return updatedPermissions;
    }));
    const permissionIsGranted = (permissionId, requestedGrantType) => {
        var _a;
        permissionId = normalizePermissionId(permissionId);
        //Start off by verifying whether or not our user has been granted the given permission
        const userPermission = (_a = permissions.value) === null || _a === void 0 ? void 0 : _a.get(permissionId);
        if (!userPermission) {
            return false;
        }
        const isGranted = (grantType, value) => {
            //Ignore grant types not requested
            const grantTypeRequested = (requestedGrantType & grantType) !== 0;
            if (!grantTypeRequested) {
                return true;
            }
            //Perform our concrete verification by returning whether the user has the given permission or not
            return value;
        };
        return (isGranted(PermissionGrantType.Read, userPermission.canRead) &&
            isGranted(PermissionGrantType.Write, userPermission.canWrite) &&
            isGranted(PermissionGrantType.Create, userPermission.canCreate) &&
            isGranted(PermissionGrantType.Delete, userPermission.canDelete));
    };
    return {
        permissions: computed(() => permissions.value),
        isLoaded: isLoaded,
        roles: computed(() => roles.value),
        assignableRoles: computed(() => assignableRoles.value),
        permissionIsGranted,
    };
});
