import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ProgressDisplay = _resolveComponent("ProgressDisplay");
    const _component_SideBarHelp = _resolveComponent("SideBarHelp");
    const _component_SideBarMenuPanel = _resolveComponent("SideBarMenuPanel");
    const _component_XSideNav = _resolveComponent("XSideNav");
    return (_ctx.loggedIn)
        ? (_openBlock(), _createBlock(_component_XSideNav, {
            key: 0,
            ref: "sideNav",
            active: "placeholder",
            navImage: $data.collapsedView ? '/assets/logo.svg' : '/assets/logo-dark.svg',
            navItems: $options.navItems,
            activeRouteName: $options.activeMenuItemRoute,
            openExpanded: true,
            onLogoClicked: _cache[1] || (_cache[1] = ($event) => (_ctx.$router.push({ name: 'home' }))),
            onSetView: _cache[2] || (_cache[2] = $event => ($data.collapsedView = $event))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_ProgressDisplay, { collapsedView: $data.collapsedView }, null, 8, ["collapsedView"]),
                _createVNode(_component_SideBarHelp, { collapsedView: $data.collapsedView }, null, 8, ["collapsedView"]),
                _createVNode(_component_SideBarMenuPanel, {
                    currentUser: _ctx.currentUser,
                    collapsedView: $data.collapsedView,
                    activeThemeName: _ctx.activeThemeName,
                    themes: $setup.appThemes,
                    onThemeSwitch: _cache[0] || (_cache[0] = ($event) => ($setup.updateTheme($event ? 'dark' : 'light')))
                }, null, 8, ["currentUser", "collapsedView", "activeThemeName", "themes"])
            ]),
            _: 1
        }, 8, ["navImage", "navItems", "activeRouteName"]))
        : _createCommentVNode("", true);
}
