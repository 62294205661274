var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createRouter, createWebHistory } from 'vue-router';
import store from '@app/store';
import { routes as settingRoutes } from './settings';
import { useEditorStore } from './../stores/editor';
import { T, $can } from '@dnx/core';
import { useConfirmStore } from '../stores/confirmation';
import { DefaultButton } from '../stores/types/Button';
import registry from '../shell/app-registry';
const authenticationState = {
    onlyUnauthenticated: 'only accessible when not authenticated',
    onlyAuthenticated: 'only accessible when authenticated',
    any: '🤷‍♂️',
};
/**
 * Collection of menu group root route names, when visiting a route lacking its own menu item but belonging to a group, the group root will be marked as active in the menu
 */
const groupRootNames = Object.freeze({
    dashboard: 'dashboards-overview',
    configuration: 'user-configuration',
});
/**
 * Validate whether the given url may be used as returnUrl
 *
 * A url may be used as returnUrl when it matches the following conditions:
 * It is a relative url
 * It is not our login page - this prevent from potentially creating a redirect loop
 * @param {String} url
 * @return {boolean}
 */
const isValidReturnUrl = url => url && url.startsWith('/') && url !== '/login';
const appTitle = 'DNX 3.0';
const shadowRoute = opts => {
    // customer-manager-customermap -> app-customer-manager
    const app = opts.app || opts.name.replace(/(.+)-.+/, '$1');
    return {
        path: `/${app}${opts.path}`,
        name: opts.name,
        meta: {
            sidebar: opts.sidebar !== false,
            app: `app-${app || opts.app}`,
            target: opts.target,
        },
        component: null,
    };
};
//PATCH: Additional routes that are replaced when the module is loaded (bootstrap)
//       shell-integration adds that
const shadowRoutes = [
    // In app-customer-manager/router
    shadowRoute({ path: '/customermap/:id/:customerName?', name: 'customer-manager-customermap' }),
    // In app-maps/router
    shadowRoute({ path: '/preview/:id', name: 'maps-preview' }),
    shadowRoute({ path: '/edit/:id?', name: 'maps-edit' }),
    shadowRoute({ path: '/create', name: 'maps-create' }),
    // In app-dashboard/router
    shadowRoute({ path: '/:dashboard_id/:group_id?', name: 'dashboard', app: 'dashboards' }),
    shadowRoute({ path: '/create/:dashboard_id', name: 'create-dashboard', app: 'dashboards' }),
    // In app-emdm/router
    shadowRoute({ path: '/edit/:type/edit/:id', name: 'emdm-editor' }),
    // In app-mangl-conversations/router
    shadowRoute({ path: '/:id', name: 'conversation', app: 'conversations' }),
    // In app-journey-composer/router
    shadowRoute({
        path: '/edit/:id?/:revisionId?',
        name: 'journey-composer-edit',
        sidebar: false,
        // temporarily disabled, open in current tab when using hot module reloading
        target: '_blank' /*module.hot ? undefined :  '_blank' */,
    }),
];
const routes = [
    {
        path: '/',
        redirect: to => Window.runtime.location && Window.runtime.location !== '/' ? { path: Window.runtime.location } : { name: 'login' },
        meta: { translationKey: 'ROUTE_LOGIN' },
    },
    {
        name: 'redirect',
        path: '/:pathMatch(.*)*',
        component: () => import('@app/components/404.vue'),
        meta: {
            authState: authenticationState.onlyAuthenticated,
            translationKey: 'ROUTE_REDIRECT',
        },
    },
    {
        name: 'login',
        path: '/login',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
            translationKey: 'ROUTE_LOGIN',
        },
        component: () => import('@app/pages/login/LoginComponent.vue'),
        //Ensure only an actual valid returnUrl querystring parameter is passed down to our component
        props: route => ({
            returnUrl: isValidReturnUrl(route.query.returnUrl) ? route.query.returnUrl : undefined,
        }),
    },
    {
        name: 'login/forgotPassword/request',
        path: '/forgotPassword/requestCode',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
            translationKey: 'ROUTE_FORGOT_PASSWORD_REQUEST',
        },
        component: () => import('@app/pages/login/forgot-password/RequestCode.vue'),
    },
    {
        name: 'login/forgotPassword/confirmRequest',
        path: '/forgotPassword/confirmRequest',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
            translationKey: 'ROUTE_CONFIRM_REQUEST',
        },
        component: () => import('@app/pages/login/forgot-password/ConfirmRequest.vue'),
    },
    {
        name: 'login/forgotPassword/confirm',
        path: '/forgotPassword/confirm',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
            translationKey: 'ROUTE_PASSWORD_CONFIRM',
        },
        component: () => import('@app/pages/login/forgot-password/Confirm.vue'),
    },
    {
        /* used in email */
        name: 'Account/SetPassword',
        path: '/Account/SetPassword',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
        },
        redirect: {
            name: 'login/setPassword',
        },
    },
    {
        /* used in email */
        name: 'Account/ForgotPasswordConfirm',
        path: '/Account/ForgotPasswordConfirm',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
        },
        redirect: {
            name: 'login/forgotPassword/confirm',
        },
    },
    {
        name: 'login/setPassword',
        path: '/login/setPassword',
        meta: {
            authState: authenticationState.onlyUnauthenticated,
            translationKey: 'ROUTE_PASSWORD_CONFIRM',
        },
        component: () => import('@app/pages/login/forgot-password/Confirm.vue'),
        props: {
            forgotPassword: false,
        },
    },
    {
        name: 'login/2fahelp',
        path: '/login/2fahelp',
        component: () => import('@app/pages/login/loginTwoFactor/LoginTwoFactorHelp.vue'),
        meta: {
            translationKey: 'AUTHENTICATION_AUTHENTICATOR_NOT_WORKING',
        },
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('@app/pages/home/Home.vue'),
        meta: {
            authState: authenticationState.onlyAuthenticated,
            translationKey: 'ROUTE_HOME',
        },
    },
    {
        name: 'user-configuration',
        path: '/account',
        redirect: { name: 'profile' },
    },
    {
        name: 'rolegroups',
        path: '/rolegroups',
        meta: {
            authState: authenticationState.onlyAuthenticated,
            translationKey: 'ROUTE_ROLEGROUPS',
        },
        component: () => import('@app/pages/rolegroups/GroupOverview.vue'),
    },
    {
        name: 'edit',
        path: '/edit/:id?/:revisionId?',
        beforeEnter(to) {
            return registry.goto(`/journey-composer${to.href}`);
            // let target = '/journey-composer/edit/';
            // if (params.id) target += params.id + '/';
            // if (params.revisionId) target += params.revisionId + '/';
            // // force reload on correct page.. fix for subroute not being initialized yet
            // // can remove this after bootstrap overhaul
            // window.location = target;
            // return true;
        },
        meta: { target: '_blank', app: 'app-journey-composer' },
    },
    {
        name: 'quality-monitoring',
        path: '/support/mangl',
        component: () => import('./../pages/support/QualityMonitoring.vue'),
        meta: {
            authState: authenticationState.onlyAuthenticated,
        },
    },
    //Add redirects
    {
        path: '/dashboard/:dashboard_id/:group_id',
        redirect: to => {
            return { path: `/dashboards/${to.params.dashboard_id}/${to.params.group_id}` };
        },
    },
    ...settingRoutes,
    ...shadowRoutes,
    ...registry.routes.flatMap(route => [
        Object.assign(Object.assign({}, route), { meta: Object.assign(Object.assign({}, route.meta), { authState: authenticationState.onlyAuthenticated, groupRootName: route.name }) }),
    ]),
];
// Called when e new (sub)app is loaded. Update routing/menu for routes not in the bridge/app-registry
registry.apploaded = app => {
    var _a, _b, _c;
    if (router.hasRoute(app.name)) {
        let existing = router.getRoutes().find(r => r.name === app.name);
        if (existing) {
            // Convert meta.auth into authenticationState symbols.
            const _d = existing.meta, { authState = typeof ((_a = app.meta) === null || _a === void 0 ? void 0 : _a.auth) !== 'undefined'
                ? ((_b = route.meta) === null || _b === void 0 ? void 0 : _b.auth)
                    ? authenticationState.onlyAuthenticated
                    : authenticationState.onlyUnauthenticated
                : authenticationState.onlyAuthenticated } = _d, remainder = __rest(_d, ["authState"]);
            const meta = Object.assign(Object.assign(Object.assign({}, remainder), app.meta), { authState });
            // Remove all routes for this app back to front.
            const killlist = router.getRoutes().filter(r => { var _a; return ((_a = r.meta) === null || _a === void 0 ? void 0 : _a.app) === app.name; });
            if (Array.isArray(killlist))
                for (let i = killlist.length - 1; i >= 0; i--)
                    router.removeRoute(killlist[i].name);
            // Add navigation to route with a target so it opens in a new tab, but only once.
            function addTargetOpener(route) {
                // if ((route.meta?.target ?? '_self') !== '_self')
                //   route.beforeEnter = (to) => location.pathname !== to.href && registry.goto(to.href, to.meta?.target === '_blank' ? to.href : undefined )
                return route;
            }
            // Add the new route
            router.addRoute(addTargetOpener(Object.assign(Object.assign(Object.assign({}, existing), app.route), { meta, children: [], component: app.route.component })));
            // Add routes for additional routes provided by the subapp
            (_c = app.route) === null || _c === void 0 ? void 0 : _c.children.forEach(subroute => {
                router.addRoute(addTargetOpener(Object.assign(Object.assign({}, subroute), { path: `/${app.route.path}${subroute.path}`.replace('//', '/'), meta: subroute.meta ? Object.assign(Object.assign(Object.assign({}, meta), subroute.meta), { app: app.name }) : meta })));
            });
        }
    }
};
//DEV: dumpRoute(routes)
const history = createWebHistory();
const router = createRouter({
    history,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    },
});
if (Window.runtime.location &&
    location.pathname !== Window.runtime.location &&
    location.href !== Window.runtime.location) {
    const location = Window.runtime.location;
    router.replace(location);
}
let confirmRouteLeaveTimeout = undefined;
//Authentication check
router.beforeEach((to, from) => __awaiter(void 0, void 0, void 0, function* () {
    function onlyAuthenticated() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield store.dispatch('login/verifyLoginState');
                if (response === true) {
                    store.dispatch('passwordPolicies/loadActivePolicy');
                    return true;
                }
                console.warn('Route expects authenticated user, but user is unauthenticated; redirecting');
                return {
                    name: 'login',
                    query: {
                        returnUrl: isValidReturnUrl(to.fullPath) ? to.fullPath : undefined,
                    },
                };
            }
            catch (error) {
                console.warn('Encountered error while checking authenticationState', error);
                return true;
            }
        });
    }
    function onlyUnauthenticated() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield store.dispatch('login/verifyLoginState');
                if (response !== true) {
                    return true;
                }
                console.warn('Route expects unauthenticated user, but user is authenticated; redirecting');
                return '/home';
            }
            catch (error) {
                console.warn('Encountered error while checking authenticationState', error);
                return true;
            }
        });
    }
    if (to.meta && to.meta.authState) {
        if (to.meta.authState === authenticationState.onlyAuthenticated) {
            return yield onlyAuthenticated();
        }
        else if (to.meta.authState === authenticationState.onlyUnauthenticated) {
            return yield onlyUnauthenticated();
        }
    }
}));
const confirmLeave = () => __awaiter(void 0, void 0, void 0, function* () {
    const confirmed = yield useConfirmStore().prompt(T('ROUTE_LEAVE_CONFIRMATION_TITLE'), T('ROUTE_LEAVE_CONFIRMATION_MESSAGE'), 'warning', {
        denyButton: DefaultButton.cancel,
        confirmButton: Object.assign(Object.assign({}, DefaultButton.close), { iconName: 'clear' }),
    });
    return confirmed;
});
//Open editor checks
router.beforeEach((to, from) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const editorStore = useEditorStore();
    clearTimeout(confirmRouteLeaveTimeout);
    if (editorStore.hasDirtyEditors) {
        const confirmed = yield confirmLeave();
        if (!confirmed)
            return false;
    }
    if ((from.meta.confirmLeave != undefined || editorStore.enforceConfirmLeave) &&
        !editorStore.enforceSkipConfirmation) {
        const shouldCheck = () => {
            //Do check if path is the same
            if (to.path == from.path)
                return false;
            if (editorStore.enforceSkipConfirmation)
                return false;
            if (editorStore.enforceSkipConfirmation || from.meta.enforceConfirmLeave)
                return true;
            if (from.meta.confirmLeave == true) {
                return editorStore.prolongedUserActivity;
            }
            else if (typeof from.meta.confirmLeave == 'function') {
                return from.meta.confirmLeave() && editorStore.prolongedUserActivity;
            }
            return false;
        };
        if (shouldCheck()) {
            const confirmed = yield confirmLeave();
            if (!confirmed)
                return false;
        }
    }
    if (to.meta.confirmLeave) {
        confirmRouteLeaveTimeout = setTimeout(() => {
            window.onbeforeunload = e => {
                e.preventDefault();
                e.returnValue = '';
            };
            useEditorStore().prolongedUserActivity = true;
        }, 5000);
    }
    window.onbeforeunload = null;
    editorStore.prolongedUserActivity = false;
    editorStore.enforceConfirmLeave = false;
    editorStore.enforceSkipConfirmation = false;
    const pageTitle = T(((_a = to === null || to === void 0 ? void 0 : to.meta) === null || _a === void 0 ? void 0 : _a.translationKey) || '') || undefined;
    // is for setting title in tabname
    document.title = `${appTitle} ${pageTitle ? `- ${pageTitle}` : ''}`;
}));
//Permission check
router.beforeEach(to => {
    const permissionId = to.meta.permissionId;
    if (permissionId) {
        if (!$can.read[permissionId])
            return '/home';
    }
});
export { router as default, authenticationState };
