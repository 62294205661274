import i18n from '@app/utils/i18n';
import {createApp, defineComponent, h} from 'vue';
import {plugin as dnxDesignPlugin, addToast} from '@dnx/core';
import App from '@app/App.vue';
import router from '@app/router';
import store from '@app/store';
import {createPinia} from 'pinia';
import singleSpaVue from 'single-spa-vue';
import {formattingPlugin} from '@app/utils/formatting';
import {plugin as widgetsPlugin} from '@dnx/widgets';
import {VueQueryPlugin} from '@tanstack/vue-query';
import {useProgressService} from './stores/progress';
import {contextMenuDirective} from './directives/context-menu';
import {useEditorStore} from './stores/editor';
import useServerMessagesService from './stores/server-messages';
import {registerBreakpointDirectives} from './directives/responsiveness';
import { initializeGlobalTooltipListener } from "./shell/tooltip";

export const lifecycle = singleSpaVue({
  createApp,
  appOptions: {
    render: () => h(App),
    el: '#application', //<-- application so it removes the loader
  },
  handleInstance: (app, props) => {
    app.config.errorHandler = function (err, _vm, info) {
      console.error(err, info);
      if (['.dev.', '-acc', 'acc.', 'localhost'].some(x => window.location.hostname.indexOf(x) >= 0)) {
        addToast('error', err.message || err);
      }
    };

    app.config.warnHandler = function (err, _vm, info) {
      console.info(err, info);
    };

    //FIXME: Code smell, but we need the vuex store to expose subapps for now
    app.config.globalProperties.$store = store;
    Window.runtime.store = store;

    app
      .use(store)
      .use(createPinia())
      .use(VueQueryPlugin)
      .use(i18n)
      .use(router)
      .use(dnxDesignPlugin, {
        setTranslationProvider(key, placeholders) {
          return i18n.global.t(key, placeholders);
        },
      })
      .use(formattingPlugin)
      .directive('context-menu', contextMenuDirective)
      .use(widgetsPlugin);

    initializeGlobalTooltipListener();
    
    registerBreakpointDirectives(app);

    initializeAdditionalVuexModules(store);

    // noinspection JSIgnoredPromiseFromCall
    useProgressService().initialize();
    //DEV: Need store before router.beforeEach is triggered!
    useEditorStore();
    useServerMessagesService().initialize();
    store.commit('login/initializeAuthentication');

    Window._shellReady.resolve();
  },
  replaceMode: true,
});

window.onerror = function (msg, src, linenum, colnum, error) {
  // Handle all remaining errors (promise, click handlers, 3rd party, etc)
  console.log(msg, src, error);
};

export default () => {
  const newRootComponent = defineComponent({
    name: 'App',
    render() {
      return h(App);
    },
  });

  const app = createApp(newRootComponent);

  app.config.errorHandler = function (err, _vm, info) {
    console.error(err, info);
    if (['.dev.', '-acc', 'acc.', 'localhost'].some(x => window.location.hostname.indexOf(x) >= 0)) {
      addToast('error', err.message || err);
    }
  };

  app.config.warnHandler = function (err, _vm, info) {
    console.info(err, info);
  };

  app
    .use(store)
    .use(createPinia())
    .use(router)
    .use(dnxDesignPlugin, {
      setTranslationProvider(key, placeholders) {
        return i18n.global.t(key, placeholders);
      },
    })
    .use(formattingPlugin)
    .use(i18n)
    .use(createLogger)

    // using two different registrations for autocomplete, typing args is not yet supported
    // https://vuejs.org/guide/reusability/custom-directives.html#directive-hooks
    .directive('context-menu', contextMenuDirective)
    .directive('context-menu:click', contextMenuDirective)
    .mount('#application');

  registerBreakpointDirectives(app);
};

function initializeAdditionalVuexModules(store) {
  const _registerModule = store.registerModule;

  // keep track in dev mode?
  store.registerModule = function () {
    const name = typeof arguments[0] === 'string' ? arguments[0] : arguments[0]?.name;
    if (store.hasModule(name)) return;
    return _registerModule.apply(this, arguments);
  };

  // allow modules to register their vuex modules
  document.dispatchEvent(new CustomEvent('dnx:vuex-ready', {detail: {vuex: store}}));

  // dev only: fire a second time -> check for proper removal of event (throw another error on store usage)?

  store.registerModule = () => {
    throw `Usage of 'registerModule' is disabled post initialization! 'registerModule' is known to break reactivity for code dependent on getters, for more information, see: https://github.com/vuejs/vuex/issues/2197`;
  };
}
