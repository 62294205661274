/**
 *  An app registry to collect and manage the subapps that can be loaded in this shell. At this point
 *  The apps are not loaded yet, so we use meta data on the subapp to prepare.
 *
 *  Metadata is extracted from the .dnx.json and package.json and will be injected into the final build!
 * */
import Uuid from '../utils/uuid';
import {dumpMenu} from '../utils/devtools';
import {pick, isEmpty} from '../utils/util.ts';
import {h, shallowRef} from 'vue';
import Skeleton from '../pages/Skeleton.vue';
import {T} from '@dnx/core';
import * as spa from 'single-spa';

const APPDEFAULTS = {
  icon: 'gear',
};

class AppRegistry extends Map {
  constructor() {
    super();
    const shell = {name: 'unknown', version: '0.0.0', ...(require('@app/../package.json') || {})};
    const docinfo = document.head.getElementsByTagName('meta');
    const env = process.env?.NODE_ENV !== 'production' ? process.env?.NODE_ENV : undefined;
    this.apploaded = undefined;
    this.routecomponents = new RouteComponentRegistry();

    // Custom event when subapp is loaded, update local appinfo.
    document.addEventListener('dnx:app-loaded', e => {
      // Module provides defaults, that are overrules in the runtime.apps config.
      // Since bundle is the name of the route we do not support multiple appentries for a single subapp!
      const {
        appmodule,
        config: {component, routes, icon, menu},
      } = e?.detail;
      const appentry = this.get(appmodule);
      appentry.active = true;
      appentry.overview = component;

      if (appentry.icon === APPDEFAULTS.icon) appentry.icon = icon;

      // Update routing
      let myroute = this._routes?.find(r => r.name === appmodule);
      if (myroute) {
        this.routecomponents.set(myroute, component);
        if (routes?.length > 0) {
          myroute.children = routes.map(route => ({
            ...route,
            meta: {
              ...route.meta,
              app: appmodule,
              groupRootName: appmodule,
            },
          }));
        }
      }

      // Notify shell to update its router
      this.apploaded?.call(null, {...appentry, route: myroute});

      // HACK: allow Vue to update etc. Signal router of a route change by faking a popstate -> pick correct component
      // remove once shell integration is finished
      let notifyDelay = 50;

      const notifyRouteComponentUpdate = () => {
        window.dispatchEvent(new PopStateEvent('popstate', {state: undefined}));

        // Allow Vue to patch the DOM
        setTimeout(() => {
          if (
            !document.querySelector('.routerView-wrapper')?.innerHTML ||
            !document.querySelector('.routerView-wrapper .wrapper')?.innerHTML
          ) {
            // apply small increase per 'missed' run up until 500ms/run, should give enough breathing room to the browser to properly initialize
            setTimeout(notifyRouteComponentUpdate, Math.min(500, (notifyDelay += 150)));
          }
        });
      };

      setTimeout(notifyRouteComponentUpdate);
    });
  }

  /** Delayed loading, since the subapp will update routing */
  // goto(href, target) {
  //   if (target) {
  //     setTimeout(() => window.open(href, target), 0)
  //   }
  //   else
  //   setTimeout(() => spa.navigateToUrl(href), 50)
  //
  //   return true
  // }

  load() {
    const apps = Window.runtime?.apps || {shell: {bundle: 'app-full', active: true}};

    for (const [name, config] of Object.entries(apps)) {
      // Dont add self
      if (config.bundle === 'app-full') continue;
      this.set(name, {
        name,
        menu: true,
        route: true,
        icon: 'gear',
        ...pick(config, 'menu', 'route', 'title', 'icon', 'name'),
      });
    }
    // invalidate => 1 route per app. name = config.bundle (default)
    this._routes = undefined;
    this._menu = undefined;
    return this;
  }

  get routes() {
    if (this._routes) return this._routes;
    this.forEach(this.buildRoute.bind(this));
    this._routes ??= []; // when running only app-full -> ensure we still have a set of routes defined
    return this._routes;
  }

  //FIXME: Hardcoded menu cleanup so journey components are not displayed if there is no permission
  cleanMenu(source) {
    const journey_composer_menu = '2fed33c4-07e6-42f3-8cbe-20d6e85b7dc5';
    const mangl_conversations_menu = '78b0d61d-36e9-4728-8fcb-f3f837c06fd0';
    const mangl_qm_form_menu = 'ac061abe-f167-4a1b-b3b8-9fac0f6c743d';
    const maps_menu = '31fe4df2-6c98-446f-a332-3a15c90f2777';
    const dashboards_menu = 'e82caa8a-53b8-4a43-8d84-08882acb04b6';
    const modules_menu = '06e532eb-2ba0-4950-82dc-0c758ad8a766';
    const modules = source.find(child => child.id === modules_menu);
    if (!modules) return;

    // Replace a legacy menu entry from db with subapp entry.
    const replaceMenu = (subapp, menuId) => {
      if (!this.has(subapp)) return; // Assume app is in app-full
      let legacy = modules.subItems.find(menu => menu.id === menuId);
      const updated = modules.subItems.find(menu => menu.routeName === subapp);

      // No legacy, then updated is removed
      if (!legacy) legacy = updated;
      if (updated) {
        updated.order = +legacy.order;
        updated.permissionId = legacy.permissionId;
      }
      modules.subItems = modules.subItems.filter(child => child !== legacy);
    };

    replaceMenu('app-journey-composer', journey_composer_menu);
    replaceMenu('app-maps', maps_menu);
    replaceMenu('app-mangl-conversations', mangl_conversations_menu);
    replaceMenu('app-mangl-qm-form', mangl_qm_form_menu);
    replaceMenu('app-emdm', 'e293ab3a-1d0c-4773-abbb-b8e3cebd568d');
    replaceMenu('app-customer-manager', '66a1327a-fa9e-4026-8e86-b1f9dd155021');
    replaceMenu('app-business-manager', '3b363e20-c522-4057-826c-5767d7994847');
    replaceMenu('app-dashboards', dashboards_menu);

    // undefined as last, sort same order alfa
    const byOrderAndName = (lhs, rhs) => {
      const a = typeof lhs.order === 'undefined' ? Number.POSITIVE_INFINITY : +lhs.order;
      const b = typeof rhs.order === 'undefined' ? Number.POSITIVE_INFINITY : +rhs.order;
      let result = a - b;
      if (0 === result) {
        result = T(lhs.displayNameResourceKey).localeCompare(T(rhs.displayNameResourceKey));
      }
      return result;
    };
    modules.subItems.sort(byOrderAndName);
  }

  // Merge the menu of different apps into the main menu.
  mergeMenu(source) {
    // Source is an initial menu const in app-full (login)
    // Source is api/menuitems when user is logged in
    // sidebar validates menu item by checking a route with the same name. Routing must be ready!

    if (!source) return source;
    // vuex store binding throws errors here
    let result = JSON.parse(JSON.stringify(source));

    const menuItem = (menu, app) => {
      let routeName = null;
      if (typeof menu?.route === 'string') routeName = menu?.route;
      else {
        if (typeof app?.route === 'string') routeName = app?.route;
        else routeName = app?.name;
      }

      //PATCH
      // Appentry should provide a translation key, but if not we patch
      const translations = {
        'app-emdm': 'MENU_EMDM',
        'app-maps': 'MENU_MAPS',
        'app-journey-report': 'MENU_JOURNEY_REPORT',
        'app-journey-composer': 'MENU_JOURNEY_COMPOSER',
        'app-customer-manager': 'MENU_CUSTOMERS',
        'app-business-manager': 'MENU_BUSINESS_MANAGER',
        'app-mangl-conversations': 'MENU_MANGL_CONVERSATIONS',
        'app-mangl-qm-form': 'MENU_MANGL_QM',
        'app-dashboards': 'MENU_DASHBOARDS'
      };
      // runtime.app does not import package.dnx.icon => patch
      const icons = {
        'app-emdm': 'template',
        'app-maps': 'map',
        'app-customer-manager': 'customers',
        'app-business-manager': 'suitcase',
        'app-mangl-conversations': 'typing',
        'app-mangl-qm-form': 'file',
      };

      let icon = 'gear';
      if (menu?.icon && APPDEFAULTS.icon !== menu.icon) icon = menu.icon;
      if (app?.icon && APPDEFAULTS.icon !== app.icon) icon = app.icon;
      if (icons[app.name]) icon = icons[app.name];

      return {
        id: Uuid.NewUuid().toString(),
        name: menu?.name || app?.name,
        icon: icon,
        subItems: [],
        routeName: routeName,
        routeParameters: null,
        routeQueryStringParameters: null,
        displayNameResourceKey: menu?.name || app.translation || translations[app.name] || app.name,
      };
    };

    try {
      for (let [name, app] of this.entries()) {
        if (!app.menu) continue;
        const parent = app.menu?.parent ? app.menu.parent : '06e532eb-2ba0-4950-82dc-0c758ad8a766';
        let menu = result.find(item => item.id === parent);

        // Parent defined, but not present => root, if multiple menu options => build submenu!
        if (!menu) {
          result.push({
            id: parent,
            name: app.menu?.group || app.title || name,
            icon: app.icon || 'gear',
            subItems: [],
            routeName: null,
            routeParameters: null,
            routeQueryStringParameters: null,
            displayNameResourceKey: app.name,
          });
          menu = result[result.length - 1];
        }

        menu.subItems.push(menuItem(app.menu, app));
      }
    } catch (e) {}

    this.cleanMenu(result);

    //DEV: dumpMenu(result);

    return result;
  }

  // Build route from registry
  buildRoute(cfg, name) {
    if (cfg?.route) {
      let route = {
        name,
        path: typeof cfg.route === 'string' ? cfg.route : `/${name.replace('app-', '').replace('mangl-', '')}`,
        component: {render: () => h(this.routecomponents.get(route))},
        children: [],
        meta: {
          // Set app for route cleanup when subapp is unloaded
          app: name, //TODO: bundle is still implied from name! cfg.bundle ? name : undefined
        },
      };

      // Show sidebar when navigating here
      if (!!cfg.menu) route.meta.sidebar = cfg.menu;

      this._routes ??= [];
      this._routes.push(route);
    }
  }
}

/**
 * Registry for keeping track of which components belong to which route
 * all components acquired via this registry will have been wrapped with a layer of reactivity.
 */
class RouteComponentRegistry {
  constructor() {
    this._registry = new Map();
  }

  /** Assign a component to the given route */
  set(routeOrName, component) {
    if (!routeOrName) return;

    let componentRef = this._registry.get(this._key(routeOrName));
    if (componentRef) componentRef.value = component;
    else componentRef = shallowRef(component);

    this._registry.set(this._key(routeOrName), componentRef);
  }

  /** Retrieve the associated component for the given route, when no component has been assigned yet, a skeleton will be returned */
  get(routeOrName) {
    if (!routeOrName) return Skeleton;

    let componentRef = this._registry.get(this._key(routeOrName));
    if (!componentRef) {
      componentRef = shallowRef(Skeleton);
      this._registry.set(this._key(routeOrName), componentRef);
    }

    return componentRef.value || Skeleton;
  }

  _key(routeOrName) {
    if (typeof routeOrName === 'string') return routeOrName;
    return routeOrName.name || 'route-component-registry-default';
  }
}

const registry = new AppRegistry().load();
export default registry;
