import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
const useToastStore = defineStore('toasts', () => {
    const toasts = ref([]);
    const addToast = (toast) => {
        toasts.value.push(Object.assign({ id: crypto.randomUUID() }, toast));
    };
    const removeToast = (toast) => {
        toasts.value = toasts.value.filter(x => x != toast);
    };
    return { addToast, removeToast, toasts: computed(() => toasts.value) };
});
export default useToastStore;
