import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, Transition as _Transition, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-e55ae9c2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "app-wrapper",
    style: { "height": "100vh" }
};
const _hoisted_2 = {
    key: 1,
    class: "routerView-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LayoutV2 = _resolveComponent("LayoutV2");
    const _component_Notifications = _resolveComponent("Notifications");
    const _component_ConfirmBox = _resolveComponent("ConfirmBox");
    const _component_ContextMenuProvider = _resolveComponent("ContextMenuProvider");
    const _component_TooltipProvider = _resolveComponent("TooltipProvider");
    const _component_PopupProvider = _resolveComponent("PopupProvider");
    const _component_NScrollbar = _resolveComponent("NScrollbar");
    const _component_router_view = _resolveComponent("router-view");
    const _component_NMessageProvider = _resolveComponent("NMessageProvider");
    const _component_NaiveConfigurationProvider = _resolveComponent("NaiveConfigurationProvider");
    return (_openBlock(), _createBlock(_component_NaiveConfigurationProvider, null, {
        default: _withCtx(() => [
            ($options.isReady)
                ? (_openBlock(), _createBlock(_component_NMessageProvider, { key: 0 }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            (_ctx.$route.meta.authState === $data.authenticationState.onlyAuthenticated)
                                ? (_openBlock(), _createBlock(_component_LayoutV2, { key: 0 }))
                                : _createCommentVNode("", true),
                            _createVNode(_component_Notifications),
                            ($setup.confirmStore.showConfirmBox)
                                ? (_openBlock(), _createBlock(_component_ConfirmBox, { key: 1 }))
                                : _createCommentVNode("", true),
                            _createVNode(_component_ContextMenuProvider),
                            _createVNode(_component_TooltipProvider),
                            _createVNode(_component_PopupProvider),
                            _createVNode(_component_router_view, null, {
                                default: _withCtx(({ Component, route }) => [
                                    (route.meta.transition != false)
                                        ? (_openBlock(), _createBlock(_Transition, {
                                            key: 0,
                                            mode: "out-in"
                                        }, {
                                            default: _withCtx(() => [
                                                (_openBlock(), _createElementBlock("div", {
                                                    class: "routerView-wrapper",
                                                    key: route.name
                                                }, [
                                                    _createVNode(_component_NScrollbar, null, {
                                                        default: _withCtx(() => [
                                                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                                        ]),
                                                        _: 2
                                                    }, 1024)
                                                ]))
                                            ]),
                                            _: 2
                                        }, 1024))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                        ]))
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }));
}
