import resources from '@app/../app/api-generated/resources';
import {messages} from '@app/utils/i18n';

async function monitorActiveLocale(store) {
  //Component agnostic monitoring of our active locale
  //Invoke a `changeLocale` action each time our `activeLocale` changes
  store.watch(
    () => store.getters['locale/activeLocale'],
    activeLocale => {
      store.dispatch('locale/changeLocale', activeLocale.id);
    }
  );
}

export default async store => {
  // setting default locale and adding it to the list of loaded resources.
  store.commit('locale/setDefaultLocale', window._dnx.defaultLocale);
  store.commit('locale/addLocale', {
    localeId: window._dnx.defaultLocale.id,
    resources: messages[window._dnx.defaultLocale.id],
  });

  const availableLocales = await resources.available();

  store.commit('locale/setAvailableLocales', availableLocales.value);

  monitorActiveLocale(store);
};
