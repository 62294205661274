import CannotSaveJourney from './CannotSaveJourney.vue';
import ReviewJourneyVue from './ReviewJourney.vue';
import NodeList from './NodeList.vue';
export default [
    {
        name: 'CannotSaveJourney',
        component: CannotSaveJourney,
    },
    {
        name: 'ReviewJourney',
        component: ReviewJourneyVue,
    },
    {
        name: 'NodeList',
        component: NodeList,
    },
];
