import { DateTime } from 'luxon';
import store from '@app/store/store';
const _customFormatters = new Map();
const _defaultFormatters = new Map();
let currentFormatterType = '';
let counterForMonths = 0;
function registerDefaultFormatter(type, formatter) {
    _defaultFormatters.set(type, formatter);
}
//Expose + make layered (module based? apex:label -> apexcharts label specific)?
function registerCustomFormatter(type, formatter) {
    _customFormatters.set(type, formatter);
}
//Read preferred locale id, can be used with Intl API for browser formatting
function getCurrentLCID() {
    return store.getters['locale/preferredLanguage'];
}
/**
 * Attempt to convert the given value to a Luxon DateTime object
 *
 * @return {DateTime|undefined}
 * */
function toLuxon(value) {
    // formatting.ts is being used at more spots, we wont only get epoch values but also datestrings numberic values etc. Therefor to transform strings in int to parse them
    // a small validation is added by +value. Meaning characters other than numbers will give back NaN and follow the if statement tree.
    if (typeof value === 'string' && Number.isFinite(parseInt(value, 10)) && Number.isFinite(+value))
        value = parseInt(value, 10); // if int is given as string
    if (typeof value === 'string')
        value = DateTime.fromISO(value); //Infer format?
    if (typeof value === 'number')
        value = DateTime.fromMillis(value); //Unix timestamp
    if (value instanceof Date)
        value = DateTime.fromJSDate(value);
    if (!DateTime.isDateTime(value))
        console.warn(`Failed to parse value: ${value} as Luxon DateTime`, value);
    return value === null || value === void 0 ? void 0 : value.setLocale(getCurrentLCID()); //Ensure user locale
}
/**
 * Force numeric days/months to 2-digit (aka 9-1-2022 -> 09-01-2022)
 *
 * @param {Intl.DateTimeFormatOptions} luxonFormat
 * @return {Intl.DateTimeFormatOptions}
 */
function fixNumericFormats(luxonFormat) {
    if ((luxonFormat === null || luxonFormat === void 0 ? void 0 : luxonFormat.day) === 'numeric')
        luxonFormat = Object.assign(Object.assign({}, luxonFormat), { day: '2-digit' });
    if ((luxonFormat === null || luxonFormat === void 0 ? void 0 : luxonFormat.month) === 'numeric')
        luxonFormat = Object.assign(Object.assign({}, luxonFormat), { month: '2-digit' });
    return luxonFormat;
}
/**
 * Attempt to format the given input as currency
 *
 * Any input parseable as float is accepted, null and undefined are treated as `0` values
 *
 * @param {*} value
 * @return {any}
 */
function formatCurrency(value) {
    //Treat null/undefined as 0
    const numericValue = parseFloat(value !== null && value !== void 0 ? value : '0');
    //Not numeric so not formattable as currency, ignore
    if (!Number.isFinite(numericValue))
        return value;
    //Take user preferred locale, format as EUR
    return numericValue.toLocaleString(getCurrentLCID(), {
        style: 'currency',
        currency: 'EUR',
    });
}
/**
 * Attempt to format the given value as widget datalabel, enforcing a max of 1 decimal point if the given value is numeric
 *
 * @param {*} value
 * @return {any}
 */
function formatWidgetDataLabel(value) {
    //Should use a more generic name once more usecases arrive for numbers with an optional single floating point when not rounded
    //Should we allow non-numeric values?
    //Currently tailored towards dashboard widgets, null may be supplied as value to enforce an empty label
    const numericValue = Number(value);
    if (!Number.isFinite(numericValue))
        return value;
    if (numericValue === 0 || numericValue % 1 === 0)
        return numericValue;
    //Number has decimal point, round to 1 digit
    return numericValue.toFixed(1);
}
/**
 * Attempt to format the given value as short date (eg. 18-11-2022)
 *
 * @param {*} value
 * @return {any}
 */
function formatDate(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return dt.toLocaleString(fixNumericFormats(DateTime.DATE_SHORT));
}
/**
 * Attempt to format the given value as short date including time (eg. 18-11-2022 13:37:42)
 *
 * @param {*} value
 * @return {any}
 */
function formatDateTime(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return dt.toLocaleString(fixNumericFormats(DateTime.DATETIME_SHORT_WITH_SECONDS));
}
function formatDateTimeNoSeconds(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return dt.toLocaleString(DateTime.DATETIME_SHORT);
}
function formatDateFromWidget(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return widgetDateTime(currentFormatterType, dt);
}
function widgetDateTime(value, dt) {
    if (value === 'hours') {
        // return dt.hour.toString() + ':' + dt.minute.toString();  daysExtra
        return dt.toLocaleString(fixNumericFormats(DateTime.TIME_24_SIMPLE));
    }
    if (value === 'days' || value === 'daysExtra') {
        return dt.day.toString() + '\u00A0' + dt.monthShort.toString();
    }
    if (value === 'months') {
        return dt.monthShort.toString() + '\u00A0' + dt.year.toString();
    }
    if (value === 'years') {
        return dt.year.toString();
    }
    if (value === 'datetime') {
        return dt.toLocaleString(fixNumericFormats(DateTime.DATETIME_SHORT_WITH_SECONDS));
    }
}
/**
 * Attempt to format the given value as time (eg. 13:37:42)
 *
 * @param {*} value
 * @return {any}
 */
function formatTime(value) {
    if (value === null || value === undefined)
        return value;
    // are we dealing with a numeric value?
    if (typeof value === 'string' || typeof value === 'number') {
        const numericValue = parseInt(value, 10); //Force base 10
        if (Number.isFinite(numericValue))
            return numericValue.toString().padStart(2, '0');
    }
    // Luxon values can be parsed as integers, start off with Luxon variant
    if (!DateTime.isDateTime(value) && typeof value === 'object') {
        //We'll be ignoring the date part
        value = DateTime.now().set({
            hour: value.hours || value.hour || 0,
            minute: value.minutes || value.minute || 0,
            second: value.seconds || value.second || 0,
        });
    }
    //Invoke toLuxon to ensure correct locale is used for our datetime
    //Value guaranteed to be Luxon DateTime at this point
    if (DateTime.isDateTime(value))
        return toLuxon(value).toLocaleString(DateTime.TIME_WITH_SECONDS);
    return value;
}
/**
 * Attempt to format the given value as username
 *
 * When an object is provided as value, will attempt to format using `firstName`, `prefix`, and `lastName`
 *
 * @param {object} value
 * @return {any}
 */
function formatUserName(value) {
    if (typeof value !== 'object')
        return value;
    //Assume user object
    return [value.firstName, value.prefix, value.lastName].filter(x => x).join(' ');
}
/**
 * Attempt to format the given value as initials
 *
 * When an object is provided as value, will attempt to use `firstName` and `lastName`
 *
 * @param value
 * @return {any}
 */
function formatInitials(value) {
    var _a, _b;
    if (typeof value !== 'object')
        return value;
    return [(_a = value.firstName) === null || _a === void 0 ? void 0 : _a.at(0), (_b = value.lastName) === null || _b === void 0 ? void 0 : _b.at(0)]
        .filter(x => x)
        .join('')
        .toLowerCase();
}
registerDefaultFormatter('date', formatDate);
registerDefaultFormatter('time', formatTime);
registerDefaultFormatter('datetime', formatDateTime);
registerDefaultFormatter('datetimeWithoutSeconds', formatDateTimeNoSeconds);
registerDefaultFormatter('hours', formatDateFromWidget);
registerDefaultFormatter('daysExtra', formatDateFromWidget);
registerDefaultFormatter('days', formatDateFromWidget);
registerDefaultFormatter('months', formatDateFromWidget);
registerDefaultFormatter('years', formatDateFromWidget);
registerDefaultFormatter('currency', formatCurrency);
registerDefaultFormatter('widgetdatalabel', formatWidgetDataLabel);
registerDefaultFormatter('username', formatUserName);
registerDefaultFormatter('initials', formatInitials);
/**
 * Format the given value using the formatter registered for the given type
 *
 * @param value
 * @param {string} type
 * @return {any}
 */
function format(value, type) {
    //Attempt to infer type when not provided?
    currentFormatterType = type;
    const formatter = _customFormatters.get(type) || _defaultFormatters.get(type);
    if (formatter)
        return formatter(value);
    return value;
}
//Register default formatters on format object? -> format.asDate = formatDate
//Should we export individual formatters? :thonking:
/**
 * Plugin for exposing `$format` function to all components contained by the app
 * @type {{install(*): void}}
 */
const formattingPlugin = {
    install(app) {
        app.config.globalProperties.$format = format;
    },
};
export { format as default, formattingPlugin };
