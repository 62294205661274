var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import fileShare from '@app/../app/api-generated/fileShare';
const createPendingShare = (fileShareId) => ({
    fileShareId,
    path: '',
    name: '',
    folders: [],
    files: [],
    loadingState: LoadingState.pending,
});
export var LoadingState;
(function (LoadingState) {
    LoadingState[LoadingState["uninitialized"] = 0] = "uninitialized";
    LoadingState[LoadingState["pending"] = 1] = "pending";
    LoadingState[LoadingState["loaded"] = 2] = "loaded";
})(LoadingState || (LoadingState = {}));
const GET_INITIAL_STATE = () => ({
    fileShares: {},
    folders: {},
});
const removeScheme = (path) => (path.startsWith('vfs:///') ? path.substr(7) : path);
const setFolderDefaults = (folder) => {
    folder.path = removeScheme(folder.path);
    folder.isExpanded = false;
    folder.loadingState = LoadingState.uninitialized;
    folder.files = [];
    folder.folders = [];
};
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    actions: {
        loadShare(_a, _b) {
            return __awaiter(this, arguments, void 0, function* ({ state, commit }, { shareId }) {
                if (shareId in state.fileShares) {
                    return;
                }
                const result = yield fileShare.getContent(shareId, '/');
                if (result.success) {
                    commit('updateShare', Object.assign(Object.assign({}, result.value), { shareId }));
                }
            });
        },
        loadFolder(_a, _b) {
            return __awaiter(this, arguments, void 0, function* ({ state, commit }, { shareId, path }) {
                const share = state.fileShares[shareId];
                if (share) {
                    commit('updateFolder', { shareId, path, state: LoadingState.pending });
                    const folder = yield fileShare.getContent(shareId, path);
                    if (folder.success) {
                        const update = folder.value;
                        // This is the name of the share, not the folder
                        delete update.name;
                        commit('updateFolder', Object.assign(Object.assign({}, folder.value), { shareId,
                            path, isExpanded: true, state: LoadingState.loaded }));
                    }
                }
            });
        },
    },
    mutations: {
        updateShare(state, _a) {
            var { shareId } = _a, payload = __rest(_a, ["shareId"]);
            if (!(shareId in state.fileShares)) {
                state.fileShares[shareId] = createPendingShare(shareId);
            }
            // Map known properties only
            const share = state.fileShares[shareId];
            for (const key in payload) {
                if (key in share) {
                    share[key] = payload[key];
                }
            }
            share.files.forEach(x => {
                x.path = x.name;
            });
            // Set folder defaults
            share.folders.forEach(x => {
                setFolderDefaults(x);
                // Add folder to lookup
                state.folders[x.path] = x;
            });
        },
        updateFolder(state, _a) {
            var { shareId, path } = _a, payload = __rest(_a, ["shareId", "path"]);
            if (!(shareId in state.fileShares)) {
                return;
            }
            // Map known properties only
            const folder = state.folders[path];
            if (folder) {
                for (const key in payload) {
                    if (key in folder) {
                        folder[key] = payload[key];
                    }
                }
                if ('files' in payload) {
                    // Set file defaults
                    folder.files.forEach(x => {
                        x.path = folder.path + x.name;
                    });
                }
                if ('folders' in payload) {
                    folder.folders.forEach(x => {
                        setFolderDefaults(x);
                        // Add folder to lookup
                        state.folders[x.path] = x;
                    });
                }
            }
        },
        expandFolder(state, { shareId, path }) {
            const share = state.fileShares[shareId];
            if (share) {
                const folder = state.folders[path];
                if (folder && !folder.isExpanded) {
                    folder.isExpanded = true;
                }
            }
        },
        collapseFolder(state, { shareId, path }) {
            const share = state.fileShares[shareId];
            if (share) {
                const folder = state.folders[path];
                if (folder && folder.isExpanded) {
                    folder.isExpanded = false;
                }
            }
        },
    },
};
