﻿<template>
  <SideBar v-if="displayElement" />

  <impersonation-overlay v-if="$store.getters['impersonation/isImpersonating']" />

  <!-- todo: Change displaying the sideNav based on the route in a different way -->
  <!-- <XSelectTheme :selectedTheme="activeThemeName" /> -->
</template>

<script>
import SideBar from './SideBar';
import ImpersonationOverlay from './ImpersonationOverlay';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'LayoutV2',
  components: {
    SideBar,
    ImpersonationOverlay,
  },
  computed: {
    ...mapGetters({
      loggedIn: 'login/loggedIn',
      activeLocale: 'locale/activeLocale',
    }),

    displayElement() {
      // allow individual routes to explicitly disable sidebar
      if (typeof this.$route?.meta?.sidebar === 'boolean') {
        return this.$route.meta.sidebar;
      }

      return !(this.$route.name === 'edit' && this.$route.matched[0].children[1].name === 'edit');
    },
  },
  watch: {
    loggedIn(newVal, oldVal) {
      if (newVal === true) {
        this.setLayoutData();
      }
    },
  },
  async created() {
    this.setLayoutData();
  },
  methods: {
    ...mapActions({
      fetchAppThemes: 'users/configuration/fetchAppThemes',
      fetchUserConfiguration: 'users/configuration/fetchUserConfiguration',
    }),
    async setLayoutData() {
      if (this.loggedIn) {
        await this.fetchAppThemes();
        await this.fetchUserConfiguration();
      }
    },
  },
};
</script>

<style lang="scss">
body {
  background: var(--main-applicationBackground, #dde9f2);
}

/* Works on Firefox */
* {
  margin: 0;
  scrollbar-width: 5px;
  scrollbar-color: color-mix(in srgb, var(--control-disabledColor), transparent 60%);

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: color-mix(in srgb, var(--control-disabledColor), transparent 60%) !important;
    border-radius: 20px;

    &:hover {
      background-color: var(--control-disabledColor);
    }
  }
}
a {
  text-decoration: none;
}
</style>
