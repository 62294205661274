import shortcuts from '@app/../app/api-generated/shortcuts';

function resolveRoute(shortcut) {
  const route = {
    name: shortcut.routeInfo.name,
    params: {...shortcut.routeInfo.routeParameters},
    query: {...shortcut.routeInfo.queryParameters},
  };

  //Upgrade Dashboard route params in case of old format
  if (shortcut.module === 'Dashboard' && route.params.id) {
    route.params.dashboard_id = route.params.id;
  }

  //Maps shortcuts should always route to the edit page
  if (shortcut.module === 'Maps') {
    route.name = 'maps-edit';
  }

  if (shortcut.module === 'JourneyComposer') {
    route.name = 'journey-composer-edit';
  }

  return route;
}

function assignRoutes(shortcuts) {
  //Add to metadata - different route types per module, one single 'primary' route?
  shortcuts.forEach(shortcut => (shortcut.routeInfo = resolveRoute(shortcut)));
}

function assignTooltips(shortcuts) {
  //Certain shortcuts display more information than just their name in their hover title
  shortcuts.forEach(shortcut => {
    shortcut.tooltip = shortcut.name;
    if (shortcut.module === 'Dashboard' && shortcut.metadata.groupName)
      shortcut.tooltip += ` - ${shortcut.metadata.groupName}`;
  });
}

const GET_INITIAL_STATE = () => ({
  recentlyCreatedShortcuts: [],
  recentlyViewedShortcuts: [],
});

export default {
  namespaced: true,
  state: GET_INITIAL_STATE(),
  getters: {
    getRecentlyCreatedShortcuts: state => state.recentlyCreatedShortcuts,
    getRecentlyViewedShortcuts: state => state.recentlyViewedShortcuts,
  },
  mutations: {
    setRecentlyCreatedShortcuts: (state, data) => (state.recentlyCreatedShortcuts = data),
    setRecentlyViewedShortcuts: (state, data) => (state.recentlyViewedShortcuts = data),
  },
  actions: {
    async fetchRecentlyCreatedShortcuts({commit}) {
      const response = await shortcuts.getAllRecentlyCreatedShortcuts(10);
      const value = (response.success && response.value) || [];

      assignRoutes(value);
      assignTooltips(value);
      commit('setRecentlyCreatedShortcuts', value);
    },
    async fetchRecentlyViewedShortcuts({commit}) {
      const response = await shortcuts.getRecentlyViewedShortcuts(10);
      const value = (response.success && response.value) || [];

      assignRoutes(value);
      assignTooltips(value);
      commit('setRecentlyViewedShortcuts', value);
    },
  },
};
