var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import store from '@app/store';
import { T } from '@app/utils/i18n';
import { useConfirmStore } from './../stores/confirmation';
import { computed } from 'vue';
import { watch } from 'vue';
const minuteInMillis = 60000;
export default () => {
    let timer;
    window.addEventListener('load', resetTimer, true);
    const events = ['mousedown', 'mousemove', 'touchstart', 'click', 'keydown', 'scroll'];
    events.forEach(function (name) {
        document.addEventListener(name, resetTimer, true);
    });
    const loggedIn = computed(() => store.getters['login/loggedIn']);
    const activePolicy = computed(() => store.getters['passwordPolicies/getActivePolicy']);
    const tokenExpired = computed(() => store.getters['passwordPolicies/isExpiredToken']);
    const activateInactivity = () => __awaiter(void 0, void 0, void 0, function* () {
        localStorage.removeItem('lastActivity');
        yield store.dispatch('login/logout');
        useConfirmStore().confirm('', T('SESSION_EXPIRED'), { status: 'warning' });
    });
    watch(loggedIn, value => {
        if (!value)
            return;
        store.dispatch('passwordPolicies/loadActivePolicy');
    }, { immediate: true });
    watch(tokenExpired, value => {
        if (!value)
            return;
        activateInactivity();
    }, { immediate: true });
    function checkInactivity() {
        return __awaiter(this, void 0, void 0, function* () {
            if (loggedIn.value && activePolicy.value) {
                const policy = activePolicy.value;
                let timeout;
                const date = new Date(Date.now());
                const hour = date.getHours();
                // This is an ugly temporary fix, but so is this whole file.
                if (hour >= 9 && hour < 18)
                    timeout = policy.officeHoursLoginExpirationTimeInMinutes;
                else
                    timeout = policy.loginExpirationTimeInMinutes;
                const lastActivity = localStorage.getItem('lastActivity');
                if (timeout && lastActivity) {
                    const maxInactivity = parseInt(lastActivity) + timeout * minuteInMillis;
                    if (maxInactivity < Date.now()) {
                        activateInactivity();
                    }
                }
            }
        });
    }
    function resetTimer() {
        return __awaiter(this, void 0, void 0, function* () {
            localStorage.setItem('lastActivity', Date.now().toString());
            clearInterval(timer);
            timer = setInterval(checkInactivity, 3000);
        });
    }
};
