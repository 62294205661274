import resources from '@app/../app/api-generated/resources';
import {default as i18n, messages, i18next} from '@app/utils/i18n';
import {Settings as LuxonSettings} from 'luxon';
// Import your language translation files

const getInitialState = () => {
  return {
    defaultLocale: undefined,

    loadedResources: [],
    availableLocales: [],
  };
};

const verifyResources = resources => {
  const missingResources = [];
  for (const key in resources) {
    if (resources[key] === '__' + key + '__') {
      missingResources.push(key);
    }
  }

  if (missingResources.length > 0) {
    console.log("The following resources are present but currently don't have any value: ", missingResources);
  }
};

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    activeLocale(state, getters, rootState, rootGetters) {
      //TODO: Add case for impersonating users, take original user locale so eg. a Dutch user doesn't suddenly get a French interface

      //Attempt to resolve our preferred language based on our current user
      //User locales are formatted as locale ids - we specifically want to return the `locale` property of the given language
      if (
        rootState.users.configuration &&
        rootState.users.configuration.userConfiguration &&
        rootState.users.configuration.userConfiguration.locale
      ) {
        const locale = state.availableLocales.find(
          l => l.id === rootState.users.configuration.userConfiguration.locale
        );
        if (locale) {
          return locale;
        }
      }

      //Simply fallback to our default locale - always available after init, guaranteed to be a valid DNX locale
      //Upon usage in our components, we may assume we have an active locale due to our render stalling until we have initialized our locale store
      return state.defaultLocale;
    },
    preferredLanguage(state, getters) {
      //Only ever undefined prior to init
      if (!getters.activeLocale) {
        return undefined;
      }

      return getters.activeLocale.language;
    },
  },

  mutations: {
    setDefaultLocale(state, data) {
      state.defaultLocale = data;
    },
    setAvailableLocales(state, data) {
      state.availableLocales = data;
    },
    addLocale(state, {localeId, resources}) {
      if (state.loadedResources.includes(localeId)) {
        return;
      }

      verifyResources(resources);

      messages[localeId] = resources;
      state.loadedResources.push(localeId);
    },
  },

  actions: {
    changeLocale: async (context, localeId) => {
      //When no localeId is given, pivot to default
      localeId = localeId || context.state.defaultLocale.id;
      //Load the resources for our given localeId if not already present
      if (!context.state.loadedResources.includes(localeId)) {
        const resourcesResponse = await resources.root(localeId);
        context.commit('addLocale', {
          localeId,
          resources: resourcesResponse.value,
        });
      }

      const locale = context.state.availableLocales.find(l => l.id === localeId);

      //Base our resources on localeId - locales can have the same `language` while sporting a different set of resources (eg. based on vendor dialect)
      i18n.global.locale = locale.id;

      i18next.changeLanguage(locale.language.toLowerCase());

      const localeChangeEvent = new CustomEvent('localeChange', {
        detail: {
          newLocale: locale.language.toLowerCase(),
        },
      });

      document.dispatchEvent(localeChangeEvent);

      LuxonSettings.defaultLocale = locale.language;
    },
  },
};
