import { DateTime } from 'luxon';
/**
 * Object providing access to well known claims present in DNX dispensed JWT tokens
 */
class DnxJwtToken {
    constructor() {
        this._claims = {};
    }
    /**
     * Parse the given JWT token
     * @param {String} token
     * @return {DnxJwtToken}
     */
    static parse(token) {
        const instance = new DnxJwtToken();
        token = token || '';
        //header.payload.signature
        const [, payload] = token.split('.');
        //Claims are provided as base64 encoded JSON in our payload
        if (payload)
            instance._claims = JSON.parse(atob(payload));
        return instance;
    }
    /**
     * Collection of all claims part of the current token
     * @return {Record<string, any>}
     */
    get claims() {
        return this._claims;
    }
    /**
     * Is the TFA part of the authentication flow still pending
     * @return {boolean}
     */
    get tfaPending() {
        return !!this._claims['TfaMarkerClaim'];
    }
    /**
     * Has the current user confirmed their TFA settings
     *
     * Only returns a value when tfaPending is true
     * @return {boolean|undefined}
     */
    get tfaConfirmed() {
        if (!this._claims['TfaMarkerClaim'])
            return undefined;
        return this._claims['TfaMarkerClaim'] === 'true';
    }
    /**
     * Is this token only eligible for password changes
     * @return {boolean}
     */
    get passwordChangePending() {
        return !!this._claims['ChangePasswordClaim'];
    }
    /**
     * Id of the user the token was assigned to
     * @return {String}
     */
    get userId() {
        return this._claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    }
    /**
     * Is the current user being impersonated by another user
     * @return {boolean}
     */
    get isImpersonating() {
        return !!this._claims['ImpersonatedBy'];
    }
    /**
     * Verify whether the current time is past the timestamp configured in the `exp` claim
     * @return {boolean}
     */
    isExpired() {
        const expiresInSeconds = this._claims['exp'];
        //No expiration claim should always be considered expired
        if (!expiresInSeconds)
            return true;
        const expireDate = DateTime.fromSeconds(expiresInSeconds, { zone: 'utc' });
        //DateTime implements the Object.prototype.valueOf function allowing for comparisons between instances
        return DateTime.utc() > expireDate;
    }
}
export { DnxJwtToken };
