import { computed, watch } from 'vue';
const useTheme = () => {
    const store = Window.runtime.store;
    const currentTheme = computed(() => store.getters['users/configuration/activeThemeName']);
    const userConfiguration = computed(() => store.getters['users/configuration/getUserConfiguration']);
    const appThemes = computed(() => store.getters['users/configuration/getAppThemes']);
    watch(userConfiguration, (newValue, oldValue) => {
        var _a;
        if (!oldValue)
            return;
        const currentThemeId = newValue.themeId;
        if (!currentThemeId) {
            localStorage.removeItem('appTheme');
            return;
        }
        const themeName = (_a = appThemes.value.find(x => x.id == currentThemeId)) === null || _a === void 0 ? void 0 : _a.name;
        localStorage.setItem('appTheme', themeName);
    }, { deep: true });
    watch(currentTheme, newValue => {
        const currWindow = window;
        currWindow.Theme.variant = newValue.toLowerCase();
    }, { immediate: true });
    return {
        currentTheme,
    };
};
export default useTheme;
