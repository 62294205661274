var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSecurityStore } from '../stores/security';
import { $can, settingRoutes as initialSettingRoutes } from '@dnx/core';
const authenticationState = Object.freeze({
    onlyUnauthenticated: 'only accessible when not authenticated',
    onlyAuthenticated: 'only accessible when authenticated',
    any: '🤷‍♂️',
});
export const settingRoutes = [
    {
        name: 'user-profile',
        path: 'profile',
        meta: {
            translationKey: 'ROUTE_USER_PROFILE',
            settingType: 'personal',
            authState: authenticationState.onlyAuthenticated,
        },
        component: () => import('@app/pages/user/UserEditor.vue'),
        props: { isProfile: true },
    },
    {
        name: 'user-manager',
        path: 'users',
        meta: {
            authState: authenticationState.onlyAuthenticated,
            settingType: 'organization',
            translationKey: 'ROUTE_USER_MANAGER',
            permissionId: 'users',
        },
        component: () => import('@app/pages/users/UserManager.vue'),
    },
    {
        name: 'user-create',
        path: 'users/create',
        meta: {
            authState: authenticationState.onlyAuthenticated,
            translationKey: 'ROUTE_USER_CREATE',
            settingType: 'organization',
            showInMenu: false,
            permissionId: 'users',
        },
        component: () => import('@app/pages/user/UserEditor.vue'),
    },
    {
        name: 'user-edit',
        path: 'users/:userId',
        meta: {
            authState: authenticationState.onlyAuthenticated,
            translationKey: 'ROUTE_USER_EDIT',
            settingType: 'organization',
            showInMenu: false,
            permissionId: 'users',
        },
        component: () => import('@app/pages/user/UserEditor.vue'),
    },
    {
        name: 'snowplow',
        path: 'snowplow',
        component: () => import('@app/pages/settings/snowplowtrackers'),
        meta: {
            translationKey: 'ROUTE_SNOWPLOW',
            permissionId: 'B13246D1-C0E0-4BA0-973A-0343F085EB64',
            settingType: 'organization',
            authState: authenticationState.onlyAuthenticated,
        },
    },
    {
        name: 'datasets',
        path: 'datasets',
        component: () => import('@app/pages/settings/datasets'),
        meta: {
            translationKey: 'ROUTE_DATASETS',
            permissionId: 'EEF9E77D-46BA-4802-B043-359E0C41755E',
            settingType: 'organization',
            authState: authenticationState.onlyAuthenticated,
        },
    },
    {
        name: 'dm-scenario',
        path: 'dm-scenario',
        component: () => import('@app/pages/settings/dmscenarios'),
        meta: {
            translationKey: 'ROUTE_DM_SCENARIO',
            permissionId: '2E20167A-AE9C-4C16-BF03-C15145C24DED',
            settingType: 'organization',
            authState: authenticationState.onlyAuthenticated,
        },
    },
    ...initialSettingRoutes,
];
const routeIsAvailable = (route) => {
    // const permissionId = route.meta.permissionId as string | undefined;
    const { permissionId } = route.meta;
    if (permissionId) {
        return $can.read[permissionId];
    }
    return true;
};
const getAvailableRoute = (routes) => {
    return routes.find(route => routeIsAvailable(route));
};
//TODO: Move this to core utils?
const wait = (milliseconds) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
};
export const routes = [
    {
        name: 'settings',
        path: '/settings',
        component: () => import('./../pages/settings'),
        children: settingRoutes,
        meta: {
            authState: authenticationState.onlyAuthenticated,
            transition: false,
        },
        beforeEnter: (to) => __awaiter(void 0, void 0, void 0, function* () {
            if (to.name != 'settings')
                return true;
            let rolesLoaded = false;
            // TODO: Wait until roles are loaded, suggestions are welcome
            while (!rolesLoaded) {
                rolesLoaded = useSecurityStore().isLoaded;
                if (!rolesLoaded) {
                    yield wait(100);
                }
            }
            const route = getAvailableRoute(settingRoutes);
            if (route) {
                return route;
            }
            return { path: '/404' };
        }),
    },
];
export const getAvailablePersonalRoutes = () => {
    return settingRoutes
        .filter(x => x.meta.settingType == 'personal' && x.meta.showInMenu != false)
        .filter(routeIsAvailable);
};
export const getAvailableOrganizationRoutes = () => {
    return settingRoutes
        .filter(x => x.meta.settingType == 'organization' && x.meta.showInMenu != false)
        .filter(routeIsAvailable);
};
