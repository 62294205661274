import { showTooltipFor } from '@dnx/core';
import { initialize } from '../stores/tooltip';
// move to /initializers folder? -> place for scripts initializing global features
// or should this be part of our app bootstrapper?
/**
 * Setup a listener automatically displaying tooltips on hover when deemed necessary
 *
 * Elements can either provide content themselves which will *always* be shown, or provide have their innerText
 * be used as content. When innerText is used, the tooltip will *only* be shown if the text has been cut off (overflow etc.)
 *
 * ```html
 * <!-- Ignored -->
 * <p>My Text</p>
 *
 * <!-- Shows `My Text` on hover (when cut off) -->
 * <p data-tooltip>My Text</p>
 *
 * <!-- Always shows `My Text` on hover -->
 * <p data-tooltip="My Text">Not My Text</p>
 * ```
 */
export function initializeGlobalTooltipListener() {
    // ensure store is initialized from app-full
    initialize();
    document.body.addEventListener('mouseover', event => {
        // should we make `p` and `span` tags auto opt-in? are there cases in which we wouldn't want content to be tooltipped automatically?
        var _a;
        const target = event.target;
        if (typeof ((_a = target === null || target === void 0 ? void 0 : target.dataset) === null || _a === void 0 ? void 0 : _a['tooltip']) !== 'string') {
            return;
        }
        const content = target.dataset['tooltip'] === '' ?
            undefined :
            target.dataset['tooltip'];
        // https://stackoverflow.com/a/10017343/3697715
        // our offsetWidth being smaller than our scrollWidth implies our element not being shown in full
        const isCutOff = target.offsetWidth < target.scrollWidth;
        // either we have predefined content, or have been cut off
        if (content || isCutOff)
            showTooltipFor(target, { body: content });
    });
}
