var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class FileShare {
    constructor() {
        this.paths = Object.freeze({
            findAll: () => `api/FileShare`,
            getById: (id) => `api/FileShare/${encodeURIComponent(id)}`,
            delete: (id) => `api/FileShare/${encodeURIComponent(id)}`,
            create: () => `api/FileShare`,
            edit: () => `api/FileShare`,
            getRights: (fileshareid) => `api/FileShare/${encodeURIComponent(fileshareid)}/rights`,
            saveRights: (fileshareid) => `api/FileShare/${encodeURIComponent(fileshareid)}/rights`,
            deleteRights: (fileshareid, userid) => `api/FileShare/${encodeURIComponent(fileshareid)}/rights/${encodeURIComponent(userid)}`,
            getTypes: () => `api/FileShare/Types`,
            getAllowedDirectories: () => `api/FileShare/AllowedDirectories`,
            getContent: (id) => `api/FileShare/share/${encodeURIComponent(id)}`,
            previewFile: (id, filepath) => {
                const __query = new QueryStringBuilder().add("filePath", filepath);
                return `api/FileShare/share/${encodeURIComponent(id)}/preview${__query.toString()}`;
            },
            downloadFile: (id, filepath) => {
                const __query = new QueryStringBuilder().add("filePath", filepath);
                return `api/FileShare/share/${encodeURIComponent(id)}/download${__query.toString()}`;
            },
            uploadFiles: (id) => `api/FileShare/share/${encodeURIComponent(id)}/uploadfiles`,
            newDirectory: (id) => `api/FileShare/share/${encodeURIComponent(id)}/newdirectory`,
            deleteFiles: (id) => `api/FileShare/share/${encodeURIComponent(id)}/deletefiles`,
        });
    }
    /**
     * FileShare.FindAll "GET api/FileShare"
     *
     * Find all FileShares
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<FileShareSourceModel[]>>}
     */
    findAll(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.GetById "GET api/FileShare/{id}"
     *
     * Get the fileshare with the specified id
     *
     * 404 If the fileshare was not found
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<FileShareSourceModel>>}
     */
    getById(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.Delete "DELETE api/FileShare/{id}"
     *
     * 404 If the fileshare was not found
     * @param {Guid} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    delete(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.Create "POST api/FileShare"
     * @param {FileShareSourceModel} fileshare
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    create(fileshare, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, fileshare);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.Edit "PUT api/FileShare"
     *
     * 404 If the fileshare was not found
     * @param {FileShareEditModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    edit(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.GetRights "GET api/FileShare/{fileShareId}/rights"
     *
     * 404 If the fileshare was not found
     * @param {Guid} fileshareid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<FileShareSourcePerUserModel[]>>}
     */
    getRights(fileshareid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/${fileshareid === undefined || fileshareid === null ? "" : encodeURIComponent(fileshareid)}/rights`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.SaveRights "POST api/FileShare/{fileShareId}/rights"
     *
     * 404 If the fileshare was not found
     * @param {FileShareSourcePerUserModel[]} model
     * @param {unknown} fileshareid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    saveRights(model, fileshareid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/${fileshareid === undefined || fileshareid === null ? "" : encodeURIComponent(fileshareid)}/rights`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.DeleteRights "DELETE api/FileShare/{fileShareId}/rights/{userId}"
     *
     * 404 If the fileshare was not found, or has no rights specified for the given user
     * @param {Guid} fileshareid
     * @param {Guid} userid
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    deleteRights(fileshareid, userid, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/${fileshareid === undefined || fileshareid === null ? "" : encodeURIComponent(fileshareid)}/rights/${userid === undefined || userid === null ? "" : encodeURIComponent(userid)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.GetTypes "GET api/FileShare/Types"
     *
     * Get all defined types of fileshare
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<EnumDescription[]>>}
     */
    getTypes(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/Types`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.GetAllowedDirectories "GET api/FileShare/AllowedDirectories"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<string[]>>}
     */
    getAllowedDirectories(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/AllowedDirectories`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.GetContent "GET api/FileShare/share/{id}"
     *
     * Get contents of the fileshare with the provided id
     *
     * 404 If the fileshare was not found
     * @param {Guid} id
     * @param {string} path If provided, the content of the specified subfolder is returned instead of the root content
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<ShareModel>>}
     */
    getContent(id, path, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            jsonRequestBodyWriter.write(request, path);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.PreviewFile "GET api/FileShare/share/{id}/preview"
     * @param {Guid} id
     * @param {string} filepath
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    previewFile(id, filepath, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("filePath", filepath);
            let path = `api/FileShare/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}/preview${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.DownloadFile "GET api/FileShare/share/{id}/download"
     * @param {Guid} id
     * @param {string} filepath
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    downloadFile(id, filepath, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("filePath", filepath);
            let path = `api/FileShare/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}/download${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    //uploadFiles omitted, only JSON bodies are supported
    /**
     * FileShare.NewDirectory "POST api/FileShare/share/{id}/newdirectory"
     * @param {Guid} id
     * @param {string} name
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    newDirectory(id, name, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}/newdirectory`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, name);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * FileShare.DeleteFiles "POST api/FileShare/share/{id}/deletefiles"
     * @param {Guid} id
     * @param {string} jsonnames
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    deleteFiles(id, jsonnames, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/FileShare/share/${id === undefined || id === null ? "" : encodeURIComponent(id)}/deletefiles`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, jsonnames);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const fileShare = new FileShare();
export { fileShare as default, FileShare };
