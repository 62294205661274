import useContextMenuStore from './../stores/context-menu';
const contextMenuDirective = {
    mounted(el, { arg, value }) {
        const eventType = arg === 'contextmenu' || arg === 'click' ? arg : 'contextmenu';
        const contextMenuStore = useContextMenuStore();
        el.addEventListener(eventType, e => {
            e.preventDefault();
            contextMenuStore.show(
            // provided value doesn't get re-evaluated upon update by vue
            // as a a compromise, allow passing in a function yielding our 'current' options
            typeof value === 'function' ? value() : value, { x: e.x, y: e.y });
        });
    },
};
export { contextMenuDirective };
