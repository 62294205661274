class Cookie {
    /**
     * Create a new cookie
     * @param {string} name Cookie name
     * @param {string} value Cookie value
     */
    constructor(name, value) {
        this.name = name;
        this.value = value;
        this.path = '/';
    }
    /**
     * Create an expired cookie
     * @param {string} name Cookie name
     * @returns {Cookie} Expired cookie
     */
    static Expired(name) {
        const cookie = new Cookie(name, '');
        cookie.expire();
        return cookie;
    }
    /**
     * Expire the current cookie
     */
    expire() {
        this.setExpiresFromDays(-999);
    }
    /**
     * Parse a 'minimal' cookie containing simply a key/value - as exposed via the document.cookie interface
     * @param {string} cookie
     * @return {MinimalCookie}
     */
    static ParseMinimal(cookie) {
        //Key=Value
        let [key, value] = cookie.split('=');
        //Should not occur - ensure no other parts accidentally end up in our value
        if (value.includes(';')) {
            value = value.substring(0, value.indexOf(';'));
        }
        return {
            name: key,
            value,
        };
    }
    /**
     * Set the cookie expires property to the given amount of days in the future
     * @param {Number} days
     */
    setExpiresFromDays(days) {
        //days * hours * minutes * seconds * milliseconds
        const daysInTime = days * 24 * 60 * 60 * 1000;
        const d = new Date();
        d.setTime(d.getTime() + daysInTime);
        this.expires = d;
    }
    /**
     * Stringify to JavaScript cookie
     * @returns {string} Stringified cookie
     */
    toString() {
        const cookieParts = [`${this.name}=${this.value}`];
        if (this.expires) {
            cookieParts.push(`expires=${this.expires.toUTCString()}`);
        }
        if (this.domain) {
            cookieParts.push(`domain=${this.domain}`);
        }
        if (this.path) {
            cookieParts.push(`path=${this.path}`);
        }
        if (this.sameSite) {
            cookieParts.push(`SameSite=${this.sameSite}`);
        }
        if (this.secure === true) {
            cookieParts.push('Secure');
        }
        if (this.httpOnly === true) {
            cookieParts.push('HttpOnly');
        }
        return cookieParts.join(';');
    }
}
export { Cookie };
