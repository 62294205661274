var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
class Security {
    constructor() {
        this.paths = Object.freeze({
            getAllRoles: () => `api/security/roles`,
            getAssignableRoles: () => `api/security/assignableroles`,
            getCurrentUserPermissions: () => `api/security/currentuserpermissions`,
            getCurrentUserRoleIds: () => `api/security/currentuserroles`,
        });
    }
    /**
     * Security.GetAllRoles "GET api/security/roles"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    getAllRoles(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/security/roles`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Security.GetAssignableRoles "GET api/security/assignableroles"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    getAssignableRoles(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/security/assignableroles`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Security.GetCurrentUserPermissions "GET api/security/currentuserpermissions"
     *
     * Get a list of all permissions + grant types granted to the current user
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<UserPermissionResponseModel[]>>} List of unique permissions granted to the current user
     */
    getCurrentUserPermissions(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/security/currentuserpermissions`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Security.GetCurrentUserRoleIds "GET api/security/currentuserroles"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<RoleModel[]>>}
     */
    getCurrentUserRoleIds(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/security/currentuserroles`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const security = new Security();
export { security as default, Security };
